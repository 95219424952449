import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Divider, Row } from 'antd';
import { AtsButtonMenu, AtsSkeleton, Button, BUTTON_TYPES } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { PopupCreateInterview, PopupCancelInterview, PopupConfirmInterview } from '_molecules';
import { getJobInterview, getJobInterviewLoading } from 'store/selectors/jobSelectors';
import { BUTTON_TEXT } from 'constants/text';
import { Can, ACTIONS, SUBJECTS, useAbility } from 'permission';
import { ReactComponent as ButtonIcon } from 'dist/icons/button_icon.svg';
import { ReactComponent as Date } from 'dist/images/date.svg';
import { ReactComponent as Comment } from 'dist/images/comment.svg';
import { utcTime, dateFormatter } from 'helpers/dateFormatter';
import { useInterviewData } from './utils';
import './style.scss';

export const Interview = ({
  accessToGoogleCalendar,
  rooms,
  cancelInterview,
  isTechnical,
  rejected,
  createInterview,
  confirmInterview,
  userData,
}) => {
  const ability = useAbility();

  const interview = useSelector(getJobInterview);

  const empty = !Object.keys(interview).length;
  const loading = useSelector(getJobInterviewLoading);
  const [showCreationPopup, setShowCreationPopup] = useState(false);
  const [openConfirmInterview, setOpenConfirmInterview] = useState(false);
  const [openDeleteInterview, setOpenDeleteInterview] = useState(false);
  const onOpenPopup = () => setShowCreationPopup(true);
  const onClosePopup = () => setShowCreationPopup(false);
  const onSubmit = values => {
    setShowCreationPopup(false);
    createInterview({
      ...values,
      locations: values.locations.map(({ value }) => value),
      create_google_event: !!values.create_google_event,
      date: values.date.format('YYYY-MM-DD'),
      type: values.type.value,
      from_time: utcTime(values.from_time.value || '12:00'),
      to_time: utcTime(values.to_time.value || '13:00'),
    });
  };

  const onOpenDeletePopup = () => setOpenDeleteInterview(true);
  const onCloseDeletePopup = () => setOpenDeleteInterview(false);

  const onCloseConfirmPopup = () => setOpenConfirmInterview(false);

  const onCancelInterview = () => {
    cancelInterview(interview.uuid);
    onCloseDeletePopup();
  };

  const showEditInterview = ability.can(ACTIONS.UPDATE, SUBJECTS.INTERVIEW);
  const deleteInterview = ability.can(ACTIONS.DELETE, SUBJECTS.INTERVIEW);

  const initialValues = useInterviewData(interview, accessToGoogleCalendar);

  const isTimePassed = interview.date && moment().isAfter(moment(`${interview.date}T${interview.from_time}Z`));

  const onConfirmInterview = () => confirmInterview(interview.uuid, setOpenConfirmInterview);

  if (loading) {
    return (
      <div className="job-interview">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {isTechnical ? 'Technical' : 'Management'} interview
        </Typography.Title2>
        <AtsSkeleton height={36} />
        <Divider />
      </div>
    );
  }

  return (
    <div className="job-interview">
      <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {isTechnical ? 'Technical' : 'Management'} interview
      </Typography.Title2>
      {empty ? (
        <Can I={ACTIONS.CREATE} a={SUBJECTS.INTERVIEW} passThrough>
          {access =>
            access ? (
              <Button
                className="job-interview__add-button"
                icon={<ButtonIcon />}
                onClick={onOpenPopup}
                disabled={rejected}
              >
                {BUTTON_TEXT.ADD_INTERVIEW}
              </Button>
            ) : (
              <Typography.Text>Interview not scheduled yet</Typography.Text>
            )
          }
        </Can>
      ) : (
        <>
          {interview.is_need_confirm && (
            <Button
              className="confirm-interview"
              type={BUTTON_TYPES.GHOST}
              onClick={() => setOpenConfirmInterview(true)}
            >
              confirm interview
            </Button>
          )}
          <AtsButtonMenu disabled={!interview.is_need_confirm && isTimePassed} className="job-interview__actions">
            {showEditInterview && (
              <li role="none" onClick={onOpenPopup}>
                Edit
              </li>
            )}
            {deleteInterview && (
              <li role="none" onClick={onOpenDeletePopup}>
                Cancel Interview
              </li>
            )}
          </AtsButtonMenu>
          <Row gutter={[32, 12]}>
            <Col span={24} lg={12} md={12} sm={24}>
              <div className="interview-item">
                <Date className="interview-item__icon" />
                <div className="interview-item__info">
                  <div className="title">Date and time {!interview.is_need_confirm && isTimePassed && '(Passed)'}</div>
                  <div className="info">
                    {dateFormatter(`${interview.date} ${interview.from_time}`, 'ddd MMM DD, YYYY H:mm')}
                    {' – '}
                    {dateFormatter(`${interview.date} ${interview.to_time}`, 'H:mm')}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} lg={12} md={12} sm={24}>
              <div className="interview-item">
                <Comment className="interview-item__icon" />
                <div className="interview-item__info">
                  <div className="title">
                    Conference rooms: {interview.locations.map(el => el.name).join(', ') || 'no rooms assigned'}
                  </div>
                  {interview.link ? (
                    <a href={interview.link} target="_blank" rel="noopener noreferrer" className="info-link">
                      {interview.link}
                    </a>
                  ) : (
                    <div className="info">no link</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Divider />
      <PopupCreateInterview
        open={showCreationPopup}
        onClose={onClosePopup}
        onOk={onSubmit}
        initialValues={initialValues}
        rooms={rooms}
        empty={empty}
        userData={userData}
        accessToCalendar={accessToGoogleCalendar}
      />
      <PopupConfirmInterview
        open={openConfirmInterview}
        startDate={interview.date ? `${interview.date}T${interview.from_time}Z` : null}
        endDate={interview.date ? `${interview.date}T${interview.to_time}Z` : null}
        userData={userData}
        onClose={onCloseConfirmPopup}
        onConfirm={onConfirmInterview}
      />
      <PopupCancelInterview
        open={openDeleteInterview}
        startDate={interview.date ? `${interview.date} ${interview.from_time}` : null}
        endDate={interview.date ? `${interview.date} ${interview.to_time}` : null}
        userData={userData}
        onClose={onCloseDeletePopup}
        onConfirm={onCancelInterview}
      />
    </div>
  );
};
