import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { subject } from '@casl/ability';
import { Divider } from 'antd';
import {
  AtsButtonMenu,
  Button,
  BUTTON_TYPES,
  Card,
  RichText,
  Icon,
  Tooltip,
  AtsSkeleton,
  Popup,
  notification,
  NOTIFICATION_TYPES,
} from '_atoms';
import { PopupBlacklist, PopupCandidateDuplicate, PopupFire } from '_molecules';
import history from 'helpers/history';
import { ACTIONS, Can, SUBJECTS, UI, useAbility } from 'permission';
import { getCandidateStatusColor } from '_templates/Candidates/utils';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { STATUSES } from 'constants/user';
import * as actions from 'actions';
import { getCandidateLoading } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getCandidateJobs } from 'store/selectors/candidateSelectors';
import { ROUTES } from 'router/constants';
import { ReactComponent as Location } from 'dist/icons/location.svg';
import { ReactComponent as Clock } from 'dist/icons/clock.svg';
import { ReactComponent as Plane } from 'dist/icons/airplane.svg';
import { ReactComponent as Business } from 'dist/icons/business.svg';
import { linkModifier } from './utils';
import './style.scss';

export const CandidateDetails = ({ candidateData, showAssignPopup, showShare, isSSC }) => {
  const ability = useAbility();
  const { id } = useParams();
  const user = useSelector(getUser);
  const jobs = useSelector(getCandidateJobs);

  const { first_name_latin, last_name_latin, first_name_local, last_name_local } = candidateData;
  const fullNameEN = (first_name_latin || last_name_latin) && `${first_name_latin} ${last_name_latin}`;
  const fullNameRu = (first_name_local || last_name_local) && `${first_name_local} ${last_name_local}`;
  const [copiedText, setCopiedText] = useState('');
  const emails = candidateData.email.map(link => linkModifier({ type: 'Email', link, linkFull: link }, copiedText));
  const phones = candidateData.phone.map(link => linkModifier({ type: 'Phone', link, linkFull: link }, copiedText));
  const skypes = candidateData.skype.map(link => linkModifier({ type: 'Skype', link, label: link }));
  const socialNetworks = candidateData.social_networks.map(n =>
    linkModifier({ type: n.network, link: n.link, label: n.link })
  );
  const links = [...phones, ...emails, ...skypes, ...socialNetworks];
  const { city, state, country, timezone, description, is_ready_to_relocate, is_ready_to_business_trips, status } =
    candidateData;
  const location = `${city ? `${city}, ` : ''}${state && state !== 'Default state' ? `${state}, ` : ''}${
    country || ''
  }`;

  const [openBlacklistPopup, setOpenBlacklistPopup] = useState(false);
  const [openFirePopup, setOpenFirePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openReviewPopup, setOpenReviewPopup] = useState(false);

  const [potentialDuplicates, setPotentialDuplicates] = useState([]);
  const [duplicatesPopup, setDuplicatesPopup] = useState(null);

  const onDuplicatesPopupClose = () => {
    setDuplicatesPopup(null);
    setPotentialDuplicates([]);
  };

  const getSSCPotentialDuplicates = () => {
    actions.getSSCPotentialDuplicatesAPI(id).then(duplicates => {
      setPotentialDuplicates(duplicates);
      if (duplicates.length) {
        notification({
          message: `Found ${duplicates.length} duplicate(s).`,
          btn: (
            <span role="none" onClick={() => setDuplicatesPopup(candidateData.uuid)}>
              Open Duplicates
            </span>
          ),
          type: NOTIFICATION_TYPES.WARNING,
        });
      }
    });
  };

  const showPopupBlacklist = () => {
    if (isSSC) getSSCPotentialDuplicates();
    setOpenBlacklistPopup(true);
  };
  const closePopupBlacklist = () => setOpenBlacklistPopup(false);

  const showPopupFire = () => {
    setOpenFirePopup(true);
  };
  const closePopupFire = () => setOpenFirePopup(false);

  const showPopupDelete = () => {
    setOpenDeletePopup(true);
  };
  const closePopupDelete = () => setOpenDeletePopup(false);

  const onOpenReviewPopup = () => {
    getSSCPotentialDuplicates();
    setOpenReviewPopup(true);
  };

  const onOpenAssignPopup = () => {
    if (isSSC) getSSCPotentialDuplicates();
    showAssignPopup();
  };
  const onCloseReviewPopup = () => setOpenReviewPopup(false);

  const deleteCandidate = () => {
    actions.deleteCandidate(id, isSSC).then(() => {
      const role = user.active_role?.name;
      const dashboard = `${ROUTES.DASHBOARD}/${role.toLowerCase().replace(/\s/g, '_')}`;
      if (isSSC) history.push(`${dashboard}?tab=ssc`);
      else history.push(ROUTES.CANDIDATES);
      closePopupDelete();
    });
  };

  const onEditClick = () => {
    if (isSSC) history.push(`/self-submitted-candidates/${id}/edit`);
    else history.push(`/candidates/${candidateData.uuid}/edit`);
  };
  const onReviewComplete = () => {
    actions.reviewCandidate(id).then(() => {
      history.push(`/candidates/${candidateData.uuid}/show`);
      onCloseReviewPopup();
    });
  };
  const onShareProfile = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(
          `${window.location.origin}/${isSSC ? 'self-submitted-candidates' : 'candidates'}/preview/${
            candidateData.uuid
          }`
        )
        .then(() => notification({ message: 'Link to profile has been copied', type: NOTIFICATION_TYPES.SUCCESS }));
    }
  };
  // const onPrintCv = () => window.print();

  const loading = useSelector(getCandidateLoading);

  const editAction = ability.can(ACTIONS.UPDATE, SUBJECTS.CANDIDATE);
  const shareProfile = ability.can(ACTIONS.READ, UI.SHARE_PROFILE);
  const blacklistAction = ability.can(ACTIONS.READ, subject(UI.BLACKLIST_CANDIDATE, { status }));
  const fireAction =
    ability.can(ACTIONS.READ, UI.FIRE_CANDIDATE) && jobs.find(j => j.candidate_status === STATUSES.HIRED);
  const deleteAction = ability.can(ACTIONS.DELETE, SUBJECTS.CANDIDATE);
  const disableAssignCandidate = status === STATUSES.BLACKLISTED || loading;

  const onClickLink = (link, type, linkFull) => e => {
    if (type === 'Phone' || type === 'Email') {
      e.preventDefault();
      setCopiedText(link + type);
      navigator.clipboard?.writeText(linkFull);
      setTimeout(() => {
        setCopiedText('');
      }, 3000);
    }
  };

  return (
    <Card className="candidate-details">
      <div className="candidate-details__header">
        {loading ? (
          <AtsSkeleton height={74} className="candidate-name__loader" />
        ) : (
          <div className="candidate-name">
            <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>{fullNameEN}</Typography.Title2>
            <Typography.Title4 weight={TYPOGRAPHY_WEIGHT.BOLD}>{fullNameRu}</Typography.Title4>
            <Status type={getCandidateStatusColor(status)}>{status}</Status>
          </div>
        )}
        {showShare ? (
          shareProfile && (
            <div className="candidate-details__buttons">
              <Button style={{ width: '100%' }} type={BUTTON_TYPES.SECONDARY} onClick={onShareProfile}>
                Share profile
              </Button>
            </div>
          )
        ) : (
          <div className="candidate-details__buttons">
            <Can I={ACTIONS.READ} a={UI.ASSIGN_CANDIDATE}>
              <Button type={BUTTON_TYPES.PRIMARY} onClick={onOpenAssignPopup} disabled={disableAssignCandidate}>
                assign to job
              </Button>
            </Can>
            <AtsButtonMenu disabled={loading}>
              {editAction && (
                <li role="none" onClick={onEditClick}>
                  Edit candidate
                </li>
              )}
              {isSSC && (
                <li role="none" onClick={onOpenReviewPopup}>
                  Mark as reviewed
                </li>
              )}
              {shareProfile && (
                <li role="none" onClick={onShareProfile}>
                  Share profile
                </li>
              )}
              {fireAction && (
                <li role="none" onClick={showPopupFire}>
                  Fire candidate
                </li>
              )}
              {blacklistAction && (
                <li role="none" onClick={showPopupBlacklist}>
                  Move to blacklist
                </li>
              )}
              {deleteAction && (
                <li role="none" className="error" onClick={showPopupDelete}>
                  Delete
                </li>
              )}
            </AtsButtonMenu>
          </div>
        )}
      </div>

      <Divider />

      <div className="candidate-details__block">
        {loading ? (
          <AtsSkeleton height={100} />
        ) : (
          links.map(({ type, link, label, linkFull }) => (
            <a
              key={link + type}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClickLink(link, type, linkFull)}
            >
              <Tooltip label={label} className="candidate-details__link__tooltip">
                <Icon type={type} className="candidate-details__link" />
              </Tooltip>
            </a>
          ))
        )}
      </div>
      {loading ? (
        <div className="candidate-details__block">
          <AtsSkeleton height={64} />
        </div>
      ) : (
        <div className="candidate-details__block">
          <Typography.Label>Location</Typography.Label>
          <InfoItem ItemIcon={Location}>{location}</InfoItem>
          <InfoItem ItemIcon={Clock}>{timezone}</InfoItem>
          {!(location && timezone) && <Typography.Text>-</Typography.Text>}
        </div>
      )}
      {loading ? (
        <div className="candidate-details__block">
          <AtsSkeleton height={64} />
        </div>
      ) : (
        <div className="candidate-details__block">
          <Typography.Label>Relocation</Typography.Label>
          <InfoItem ItemIcon={Plane}>{is_ready_to_relocate && 'Ready to relocate'}</InfoItem>
          <InfoItem ItemIcon={Business}>{is_ready_to_business_trips && 'Ready for business trips'}</InfoItem>
          {!(is_ready_to_relocate && is_ready_to_business_trips) && <Typography.Text>-</Typography.Text>}
        </div>
      )}
      {loading ? (
        <div className="candidate-details__block">
          <AtsSkeleton height={32} />
        </div>
      ) : (
        <div className="candidate-details__block">
          <Typography.Label>Description</Typography.Label>
          <RichText text={description || '-'} />
        </div>
      )}
      <PopupBlacklist candidate={candidateData} open={openBlacklistPopup} onClose={closePopupBlacklist} isSSC={isSSC} />
      <PopupFire open={openFirePopup} onClose={closePopupFire} />
      <DeleteCandidatePopup
        open={openDeletePopup}
        candidate={candidateData}
        onClose={closePopupDelete}
        onDelete={deleteCandidate}
      />
      <Popup
        open={!!openReviewPopup}
        title="Mark candidate as reviewed"
        cancelButtonProps={{
          children: 'cancel',
          onClick: onCloseReviewPopup,
        }}
        okButtonProps={{
          children: 'mark',
          onClick: onReviewComplete,
        }}
      >
        <Typography.Text>
          {`Do you really want to mark ${candidateData.first_name_latin} ${candidateData.last_name_latin} as reviewed?`}
        </Typography.Text>
      </Popup>
      <PopupCandidateDuplicate
        open={!!duplicatesPopup}
        candidateDuplicates={potentialDuplicates}
        duplicate={duplicatesPopup}
        onClose={onDuplicatesPopupClose}
        loading={duplicatesPopup && !potentialDuplicates.length}
      />
    </Card>
  );
};

export const DeleteCandidatePopup = ({ open, candidate = {}, onClose, onDelete }) => (
  <Popup
    open={open}
    title={POPUP_LABELS.DELETE_CANDIDATE}
    className="delete-candidate-popup"
    cancelButtonProps={{ onClick: onClose, children: 'Back to page' }}
    okButtonProps={{
      onClick: onDelete,
      children: BUTTON_TEXT.DELETE,
    }}
  >
    <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>
      {candidate.first_name_latin} {candidate.last_name_latin}
    </Typography.Text>
    <Typography.Caption className="delete-candidate-popup__local-name">
      {candidate.first_name_local} {candidate.last_name_local}
    </Typography.Caption>
    <Typography.Text className="delete-candidate-popup__warning">{POPUP_BODY.DELETE_CANDIDATE}</Typography.Text>
  </Popup>
);

const InfoItem = ({ ItemIcon, children }) =>
  children ? (
    <div className="info-item">
      <ItemIcon className="info-item__icon" />
      {children}
    </div>
  ) : null;
