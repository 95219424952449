import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Checkbox, DatePicker, Input, Popup, Select } from '_atoms';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { Typography } from '_atoms/Typography';
import { getRooms } from 'store/selectors/jobSelectors';
import { BUTTON_TEXT } from 'constants/text';
import { INTERVIEW_TYPES } from 'constants/job';
import { utcTimeForRooms } from 'helpers/dateFormatter';
import { getRoomsOption } from 'actions';
import { fromTimeOptions, toTimeOptions } from './constants';
import './style.scss';

export const PopupCreateInterview = ({ open, onClose, onOk, initialValues, empty, userData = {}, ...props }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const rooms = useSelector(getRooms);
  const onSubmit = () => onOk(values);
  const onChangeValue = key => value => setValues(v => ({ ...v, [key]: value }));

  useEffect(() => {
    setValues(initialValues);
  }, [open, initialValues]);

  useEffect(() => {
    if (values.from_time && values.to_time && values.date && open) {
      dispatch(
        getRoomsOption(
          values.date.format('YYYY-MM-DD'),
          utcTimeForRooms(values.from_time.value),
          utcTimeForRooms(values.to_time.value)
        )
      );
    }
  }, [values.from_time, values.date, values.to_time, open]);

  return (
    <Popup
      className="popup-create-interview mobile-full-size"
      open={open}
      title={empty ? 'Add interview' : 'Edit interview'}
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{
        onClick: onSubmit,
        children: empty ? BUTTON_TEXT.ADD_INTERVIEW : BUTTON_TEXT.SAVE,
        disabled: !values.date || !values.type,
      }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">{`${userData.first_name_latin} ${userData.last_name_latin}`}</Typography.Text>
        <Typography.Caption className="user-info__subtitle">{`${userData.first_name_local} ${userData.last_name_local}`}</Typography.Caption>
        <Typography.Caption className="user-info__subtitle">
          {userData.specialities?.[0]?.level} {userData.specialities?.[0]?.speciality}
        </Typography.Caption>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <DatePicker label="Date" value={values.date} onChange={onChangeValue('date')} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Select
            label="From"
            inPopup
            options={fromTimeOptions}
            value={values.from_time}
            onChange={v => {
              onChangeValue('to_time')(toTimeOptions[v.index + 1]);
              onChangeValue('from_time')(v);
            }}
          />
        </Col>
        <Col span={12}>
          <Select
            label="To"
            inPopup
            options={toTimeOptions.slice(values.from_time.index, toTimeOptions.length)}
            value={values.to_time}
            onChange={onChangeValue('to_time')}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Select
            label="Meeting type"
            options={INTERVIEW_TYPES.filter(el => !(!props.accessToCalendar && el === INTERVIEW_TYPES[0])).map(
              item => ({ value: item, label: item })
            )}
            value={values.type}
            onChange={onChangeValue('type')}
          />
          {values.type?.value === INTERVIEW_TYPES[1] && (
            <Typography.Label className="automatically-message">
              The link to the meeting is generated automatically
            </Typography.Label>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Select
            label="Meeting room"
            options={rooms.map(item => ({ value: item.id, label: item.name }))}
            value={values.locations}
            mode={SELECT_TYPES.MULTIPLE}
            onChange={onChangeValue('locations')}
            disabled={!props.accessToCalendar}
          />
        </Col>
      </Row>
      {['Skype', 'Other'].includes(values.type?.value) && (
        <Row>
          <Col span={24}>
            <Input
              label="Meeting link"
              value={values.link}
              onChange={e => onChangeValue('link')(e.target.value)}
              placeholder="Please enter the link to join the meeting"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Checkbox
            label="Add interview to calendar"
            isSelected={values.create_google_event}
            onChange={e => onChangeValue('create_google_event')(e.target.checked)}
            disabled={!props.accessToCalendar}
          />
        </Col>
      </Row>
    </Popup>
  );
};
