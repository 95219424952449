import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  Checkbox,
  Table,
  Tabs,
  Select,
  Typography,
  TYPOGRAPHY_WEIGHT,
  Divider,
  notification,
  NOTIFICATION_TYPES,
} from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SELECT_TYPES } from '_atoms/Select/constants';
import {
  PopupCloseDeclineOpening,
  PopupCreateInterview,
  PopupSubmitDecision,
  PopupSubmitOfferDecision,
} from '_molecules';
import { NewJobs, SelfSubmittedCandidatesDashboard } from '_organisms';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import {
  createInterview,
  declineApproveOpening,
  getAllCompanies,
  getDocumentsByUUID,
  getCandidatesTableDashboardAPI,
  getHRLJobsTableDashboardAPI,
  getHRLServicesTableDashboardAPI,
  getInterviewFeedbacksDashboardAPI,
  getListUsersByRole,
  saveInterviewFeedbacks,
  submitDecision,
  submitOffer,
  submitDecisionWithOppening,
  getHiredCandidate,
} from 'actions';
import useScreenResize from 'helpers/useScreenResize';
import { useQuery } from 'helpers/useLocation';
import { getCompanies } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import {
  jobTableDataMapper,
  serviceTableDataMapper,
  useTabs,
  isServiceGrouping,
  CANDIDATES_TABLES_MAPPERS,
} from './utils';
import {
  INITIAL_VALUES,
  COLUMNS_OPENINGS_SERVICE,
  INITIAL_VALUES_1,
  OPENINGS_GROUP,
  COLUMNS_OPENINGS_SERVICE_TOP,
  COLUMNS_OPENINGS_JOB_TOP,
  INITIAL_VALUES_2,
  COLUMNS_OPENINGS_JOB,
  OPENING_STATUSES_OPTIONS,
  CANDIDATES_GROUP,
  CANDIDATES_TABLE_COLUMNS,
  COMPANY_ALL,
} from './constants';
import './style.scss';
import { ROLES } from '../../permission';
import { useInterviewData } from '../../_organisms/Interview/utils';
import { PopupCreateFeedback } from '../../_molecules/PopupCreateFeedback';
import { PopupCreateOffer } from '../../_molecules/PopupCreateOffer';
import { utcTime } from '../../helpers/dateFormatter';

export const DashboardHRLead = () => {
  const query = useQuery();

  const [newJobsTrigger, setNewJobsTrigger] = useState();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(query.tab || 'openings');
  const tabs = useTabs(loading);

  return (
    <div className="dashboard-hr-lead">
      <div className="dashboard-hr-lead__banner-wrapper">
        <div className="top-banner" />
        <NewJobs trigger={newJobsTrigger} isHrl />
      </div>
      <Tabs className="dashboard-hr-lead__tabs" activeTab={tab} onChange={setTab} items={tabs} />
      {
        {
          [tabs[0].key]: <AllOpeningsService setTabsLoading={setLoading} onChangeTrigger={setNewJobsTrigger} />,
          [tabs[1].key]: <MyActiveCandidates leadDashboard setTabsLoading={setLoading} />,
          [tabs[2].key]: <SelfSubmittedCandidatesDashboard setTabsLoading={setLoading} dashboard="hr-lead" />,
        }[tab]
      }
    </div>
  );
};

const AllOpeningsService = ({ setTabsLoading, onChangeTrigger }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES_1);
  const [openingStatus, setOpeningStatus] = useState([OPENING_STATUSES_OPTIONS[3]]);
  const [company, setCompany] = useState(COMPANY_ALL);
  const [grouping, setGrouping] = useState({ ...OPENINGS_GROUP[0], tableData: OPENINGS_GROUP[1].tableData });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showOpeningDeclinePopup, setShowOpeningDeclinePopup] = useState({});

  const companies = useSelector(getCompanies);
  const companyOptions = useMemo(
    () => [COMPANY_ALL, ...companies.map(c => ({ value: c.uuid, label: c.name }))],
    [companies]
  );

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onClickTitle =
    ({ uuid, name }) =>
    () => {
      if (isServiceGrouping(grouping.value))
        window.open(`/jobs?services=[{"value":"${uuid}","label":"${name}"}]`, '_blank', 'noopener noreferrer');
      else window.open(`/jobs/${uuid}/show/openings`, '_blank', 'noopener noreferrer');
    };

  const onChangeGrouping = v => {
    setGrouping(v);
    getTableData(v);
  };

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      const options = { ...tableOptions, page: item, offset: (item - 1) * tableOptions.limit.value };
      setTableOptions(options);
      getTableData(grouping, options);
    } else if (field === 'limit') {
      const options = { ...tableOptions, limit: item, offset: 0, page: 1 };
      setTableOptions(options);
      getTableData(grouping, options);
    } else {
      const options = { ...tableOptions, [field]: item };
      setTableOptions(options);
      getTableData(grouping, options);
    }
  };

  const onOpeningAction = ({ job_uuid, service_uuid, ...d }, type) => {
    if (type === 'approve') {
      declineApproveOpening(job_uuid, service_uuid, type).then(() => {
        getTableData();
        onChangeTrigger(new Date());
      });
    } else {
      setShowOpeningDeclinePopup({ ...d, job_uuid, service_uuid, type });
    }
  };

  const getTableData = (group = grouping, options = tableOptions) => {
    setLoading(true);
    setTabsLoading(true);
    if (isServiceGrouping(group.value)) {
      getHRLServicesTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
        company: company.value,
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_1, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = serviceTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_1);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
          setTabsLoading(false);
        });
    } else {
      getHRLJobsTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
        company: company.value,
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_2, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = jobTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_2);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
          setTabsLoading(false);
        });
    }
  };

  const onCloseCloseOpeningPopup = () => setShowOpeningDeclinePopup({});
  const onDeclineOpening = reason => {
    const { job_uuid, service_uuid, type } = showOpeningDeclinePopup;
    declineApproveOpening(job_uuid, service_uuid, type, { reason }).then(() => getTableData());
    setShowOpeningDeclinePopup({});
  };

  useEffect(() => dispatch(getAllCompanies()), []);
  useEffect(getTableData, [openingStatus, company]);
  useScreenResize('md', tableRef);

  return (
    <>
      <Table
        innerRef={tableRef}
        rowKey="uuid"
        className={cn('all-openings', grouping.tableData)}
        cardTitle="Openings"
        cardCount={tableOptions.itemsCount}
        emptyComponent={<OpeningsTableEmpty />}
        extra={
          <>
            <Select
              labelInValue
              options={OPENING_STATUSES_OPTIONS}
              value={openingStatus}
              onChange={setOpeningStatus}
              mode={SELECT_TYPES.MULTIPLE}
              clearIcon={<div />}
            />
            <div className="filter-selectors">
              <div className="filter-container">
                <SelectSmall
                  label="Company"
                  options={companyOptions}
                  value={company}
                  onChange={setCompany}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
              <Divider type="vertical" />
              <div className="filter-container">
                <SelectSmall
                  label="Group by"
                  options={OPENINGS_GROUP}
                  value={grouping}
                  onChange={onChangeGrouping}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
            </div>
          </>
        }
        showCardFooter={tableData.length}
        columns={isServiceGrouping(grouping.tableData) ? COLUMNS_OPENINGS_SERVICE_TOP : COLUMNS_OPENINGS_JOB_TOP}
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        updateParams={onChangeFilter}
        expandable={{
          expandedRowKeys,
          expandedRowRender: ({ openings }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('all-openings__sub-table', grouping.tableData)}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
                data={openings}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('all-openings__sub-table all-openings__sub-table-mobile', grouping.tableData)}
                data={openings}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={onClickTitle(record)}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.service && <Typography.Text className="name__extra">{record.service}</Typography.Text>}
              </div>
              <div className="expanded-row__extra">
                <Typography.Caption>Total active candidates: {record.candidates_count}</Typography.Caption>
                <Typography.Caption>Total openings: {record.openings_count}</Typography.Caption>
              </div>
            </div>
          ),
        }}
      />
      <PopupCloseDeclineOpening
        open={!!showOpeningDeclinePopup.opening}
        opening={showOpeningDeclinePopup.opening}
        jobName={showOpeningDeclinePopup.job_name}
        onClose={onCloseCloseOpeningPopup}
        confirm={onDeclineOpening}
      />
    </>
  );
};

export const MyActiveCandidates = ({ leadDashboard, setTabsLoading }) => {
  const dispatch = useDispatch();

  const dashboard = window.location.pathname.split('/').pop().replace('_', '-');

  const tableRef = useRef(null);
  const user = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [count, setCount] = useState(0);
  const [grouping, setGrouping] = useState(CANDIDATES_GROUP[0]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [recruiters, setRecruiters] = useState(leadDashboard ? [{ value: user.uuid, label: user.full_name }] : []);
  const [recruitersOptions, setRecruitersOptions] = useState([]);
  const [expandedCVS, setExpandedCVS] = useState({});

  const [decisionPopup, setDecisionPopup] = useState({
    open: false,
    activeStep: 0,
    isFinalDecision: false,
    userData: {},
    jobId: '',
    stepId: '',
    candidateId: '',
    body: {},
    link: '',
  });

  const interviewInitialValues = useInterviewData({}, user.profile.has_calendar_access);
  const [interviewPopup, setInterviewPopup] = useState({
    open: false,
    empty: true,
    candidateName: '',
    jobId: '',
    stepId: '',
    candidateId: '',
    userData: {},
  });
  const [feedbackPopup, setFeedbackPopup] = useState({
    open: false,
    feedback: undefined,
    userData: {},
    jobId: '',
    stepId: '',
    candidateId: '',
    scorecardId: '',
  });
  const [offerPopup, setOfferPopup] = useState({ open: false, userData: {}, jobId: '', stepId: '', candidateId: '' });
  const [offerDecisionPopup, setOfferDecisionPopup] = useState({
    open: false,
    userData: {},
    activeStep: 0,
    jobId: '',
    stepId: '',
    candidateId: '',
  });

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, limit: item, offset: 0, page: 1 }));
    } else if (field === 'only_with_actions') {
      setTableOptions(s => ({ ...s, only_with_actions: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };
  const setSorting = sort => onChangeFilter('sorting', sort);
  const onChangeWithActions = e => onChangeFilter('only_with_actions', e.target.checked);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onGroupingChange = newGrouping => {
    setGrouping(g => ({ ...newGrouping, tableData: g.tableData }));
  };

  const getTableData = () => {
    setLoading(true);
    setTabsLoading(true);
    getCandidatesTableDashboardAPI(
      dashboard,
      {
        limit: tableOptions.limit.value,
        offset: tableOptions.offset,
        sorting_field: tableOptions.sorting.field,
        sorting: tableOptions.sorting.order,
        only_with_actions: tableOptions.only_with_actions,
        recruiters: recruiters.map(r => r.value),
      },
      grouping.value
    )
      .then(d => {
        setTableData([]);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.count);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableData(CANDIDATES_TABLES_MAPPERS[grouping.value](d.results, user));
        setExpandedRowKeys(d.results.map((_, index) => index));

        const docsUniqueUUIDs = [
          ...new Set(
            grouping.value
              ? d.results.flatMap(r => r.candidates.map(item => (Array.isArray(item.cvs) ? item.cvs : [])))
              : d.results.flatMap(item => (Array.isArray(item.cvs) ? item.cvs : []))
          ),
        ];
        if (docsUniqueUUIDs.length)
          getDocumentsByUUID(docsUniqueUUIDs).then(cvs => {
            setTableData(data =>
              data.map(row =>
                grouping.value
                  ? {
                      ...row,
                      candidates: row.candidates.map(candidate => ({
                        ...candidate,
                        cvs: [
                          ...candidate.cvs.map(cv => cvs.find(c => c.uuid === cv.uuid) || cv),
                          ...candidate.cvs_links.map(file => ({ uuid: file.uuid, file, filename: file, link: true })),
                        ],
                      })),
                    }
                  : {
                      ...row,
                      cvs: [
                        ...row.cvs.map(cv => cvs.find(c => c.uuid === cv.uuid) || cv),
                        ...row.cvs_links.map(file => ({ uuid: file.uuid, file, filename: file, link: true })),
                      ],
                    }
              )
            );
          });
      })
      .catch(() => {
        setTableData([]);
        setCount(0);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableOptions(INITIAL_VALUES);
      })
      .finally(() => {
        setLoading(false);
        setTabsLoading(false);
      });
  };

  const onActionClick = record => () => {
    switch (record.action.button.label) {
      case 'Submit Decision':
      case 'Submit Final Decision':
        if (record.status_step.step_number >= 6) {
          dispatch(getHiredCandidate(record.job.uuid, record.candidate.uuid));
          setOfferDecisionPopup({
            open: true,
            userData: {
              full_name_latin: record.candidate.full_name_latin,
              full_name_local: record.candidate.full_name_local,
              speciality: '',
              link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
            },
            activeStep: record.status_step.step_number,
            jobId: record.job.uuid,
            stepId: record.job.step_uuid,
            candidateId: record.candidate.uuid,
          });
        } else {
          setDecisionPopup({
            open: true,
            activeStep: record.status_step.step_number,
            isFinalDecision: record.action.button.isFinalDecision,
            userData: {
              full_name_latin: record.candidate.full_name_latin,
              full_name_local: record.candidate.full_name_local,
              speciality: '',
            },
            jobId: record.job.uuid,
            stepId: record.job.step_uuid,
            candidateId: record.candidate.uuid,
            body: {
              candidate: record.candidate.uuid,
              comment: '',
              positive: false,
              step: record.job.step_uuid,
            },
            link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
          });
        }
        break;
      case 'Schedule Interview':
        setInterviewPopup({
          open: true,
          candidateName: record.candidate.full_name_latin,
          empty: true,
          jobId: record.job.uuid,
          stepId: record.job.step_uuid,
          candidateId: record.candidate.uuid,
          userData: {
            full_name_latin: record.candidate.full_name_latin,
            full_name_local: record.candidate.full_name_local,
            speciality: '',
            link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
          },
        });
        break;
      case 'Provide Feedback':
        getInterviewFeedbacksDashboardAPI(record.job.uuid, record.job.step_uuid, record.candidate.uuid, user.uuid).then(
          feedbacks => {
            const feedback = feedbacks.find(f => f.approver.uuid === user.uuid);
            setFeedbackPopup({
              open: true,
              feedback: { ...feedback, comment_initial: feedback.comment },
              link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
              userData: {
                link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
                full_name_latin: record.candidate.full_name_latin,
                full_name_local: record.candidate.full_name_local,
                speciality: '',
              },
              jobId: record.job.uuid,
              stepId: record.job.step_uuid,
              candidateId: record.candidate.uuid,
              scorecardId: feedback.uuid,
            });
          }
        );
        break;
      case 'Add Offer':
        setOfferPopup({
          open: true,
          userData: {
            link: `/jobs/${record.job.uuid}/show/steps/${record.job.step_uuid}/${record.candidate.uuid}`,
            full_name_latin: record.candidate.full_name_latin,
            full_name_local: record.candidate.full_name_local,
            speciality: '',
          },
          jobId: record.job.uuid,
          stepId: record.job.step_uuid,
          candidateId: record.candidate.uuid,
        });
        break;
      default:
    }
  };

  const onCloseDecision = () => {
    setDecisionPopup({
      open: false,
      activeStep: 0,
      isFinalDecision: false,
      userData: {},
      jobId: '',
      stepId: '',
      candidateId: '',
      body: {},
      link: '',
    });
  };

  const onSaveDecision = (isFinal, data) => {
    const body = data;
    if (body.positive) delete body.reason;
    dispatch(
      submitDecision(
        decisionPopup.jobId,
        decisionPopup.stepId,
        decisionPopup.candidateId,
        body,
        decisionPopup.isFinalDecision
      )
    )
      .then(() => {
        onCloseDecision();
        getTableData();
      })
      .catch(() => {
        notification({
          message: 'The content is out of date, please refresh the page',
          type: NOTIFICATION_TYPES.ERROR,
        });
      });
  };

  const onCloseInterview = () => {
    setInterviewPopup({
      open: false,
      candidateName: '',
      empty: true,
      userData: {},
    });
  };

  const onSaveInterview = data => {
    dispatch(
      createInterview(interviewPopup.jobId, interviewPopup.stepId, interviewPopup.candidateId, {
        ...data,
        locations: data.locations.map(({ value }) => value),
        create_google_event: !!data.create_google_event,
        date: data.date.format('YYYY-MM-DD'),
        type: data.type.value,
        from_time: utcTime(data.from_time.value || '12:00'),
        to_time: utcTime(data.to_time.value || '13:00'),
      })
    )
      .then(() => {
        onCloseInterview();
        getTableData();
      })
      .catch(() => {
        notification({
          message: 'The content is out of date, please refresh the page',
          type: NOTIFICATION_TYPES.ERROR,
        });
      });
  };

  const onSaveFeedback = data => {
    saveInterviewFeedbacks(
      feedbackPopup.jobId,
      feedbackPopup.stepId,
      feedbackPopup.candidateId,
      feedbackPopup.scorecardId,
      { ...data, english_level: data.english_level?.value, level: data.level?.value }
    ).then(() => {
      onCloseFeedback();
      getTableData();
    });
  };

  const onCloseFeedback = () => {
    setFeedbackPopup({
      open: false,
      feedback: undefined,
      userData: {},
      jobId: '',
      stepId: '',
      candidateId: '',
      scorecardId: '',
    });
  };

  const onSaveOffer = data => {
    dispatch(submitOffer(offerPopup.jobId, offerPopup.stepId, offerPopup.candidateId, data))
      .then(() => {
        onCloseOffer();
        getTableData();
      })
      .catch(() => {
        notification({
          message: 'The content is out of date, please refresh the page',
          type: NOTIFICATION_TYPES.ERROR,
        });
      });
  };

  const onCloseOffer = () => {
    setOfferPopup({ open: false, userData: {}, jobId: '', stepId: '', candidateId: '' });
  };

  const onCloseSubmitOfferPopup = () => {
    setOfferDecisionPopup({ open: false, userData: {}, activeStep: 0, jobId: '', stepId: '', candidateId: '' });
  };

  const onSubmitOfferPopup = body => {
    dispatch(
      submitDecisionWithOppening(
        offerDecisionPopup.jobId,
        offerDecisionPopup.stepId,
        offerDecisionPopup.candidateId,
        body
      )
    )
      .then(() => {
        onCloseSubmitOfferPopup();
        getTableData();
      })
      .catch(() => {
        notification({
          message: 'The content is out of date, please refresh the page',
          type: NOTIFICATION_TYPES.ERROR,
        });
      });
  };

  useEffect(() => {
    getListUsersByRole({
      role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD, ROLES.HR_LEAD],
    })
      .then(users => {
        setRecruitersOptions(users.map(u => ({ value: u.uuid, label: u.full_name })));
      })
      .catch(() => {
        setRecruitersOptions([]);
      });
  }, []);

  useEffect(getTableData, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.only_with_actions,
    grouping.value,
    recruiters,
  ]);
  useScreenResize('md', tableRef, !grouping.tableData);

  return (
    <>
      <Table
        innerRef={tableRef}
        rowKey="uuid"
        className={cn('active-candidates', grouping.tableData)}
        cardTitle="Active candidates"
        cardCount={count}
        emptyComponent={<ActiveCandidatesTableEmpty />}
        extra={
          <>
            {leadDashboard && (
              <Select
                className="recruiters-filter"
                mode={SELECT_TYPES.MULTIPLE}
                value={recruiters}
                onChange={setRecruiters}
                options={recruitersOptions}
                optionFilterProp="label"
              />
            )}
            <Checkbox
              label="Show only with action"
              isSelected={tableOptions.only_with_actions}
              onChange={onChangeWithActions}
            />
            <div className="filter-container">
              <SelectSmall
                label="Group by"
                options={CANDIDATES_GROUP}
                value={grouping}
                onChange={onGroupingChange}
                placement={PLACEMENTS.BOTTOM_RIGHT}
              />
            </div>
          </>
        }
        columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][0](
          tableOptions.sorting,
          setSorting,
          leadDashboard,
          user.uuid,
          expandedCVS,
          setExpandedCVS,
          onActionClick
        )}
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        tooltipInfo="One candidate may be mentioned more than once (for example, if assigned to more than one job)"
        updateParams={onChangeFilter}
        expandable={
          grouping.tableData && {
            expandedRowKeys,
            expandedRowRender: ({ candidates }) => (
              <>
                <Table
                  rowKey="uuid"
                  className={cn('active-candidates__sub-table', grouping.tableData)}
                  columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1](
                    user.uuid,
                    leadDashboard,
                    expandedCVS,
                    setExpandedCVS,
                    onActionClick
                  )}
                  data={candidates}
                  showCardHeader={false}
                  showCardFooter={false}
                />
                <TableMobile
                  className={cn('active-candidates__sub-table active-candidates__sub-table-mobile', grouping.tableData)}
                  data={candidates}
                  columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1](
                    user.uuid,
                    leadDashboard,
                    expandedCVS,
                    setExpandedCVS,
                    onActionClick
                  )}
                />
              </>
            ),
            expandIcon: ({ expanded, record }) => (
              <div role="none" className="expanded-row">
                <div className="expanded-row__info">
                  <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                  <Typography.Text className="name">
                    {record.name}
                    <span>{record.count}</span>
                  </Typography.Text>
                  {!!record.extra && <Typography.Text className="extra">{record.extra}</Typography.Text>}
                </div>
              </div>
            ),
          }
        }
        defaultTableMobile={!grouping.tableData}
      />
      <PopupSubmitDecision
        open={decisionPopup.open}
        onSave={onSaveDecision}
        onClose={onCloseDecision}
        isFinalDecision={decisionPopup.isFinalDecision}
        userData={decisionPopup.userData}
        activeStep={decisionPopup.activeStep - 1}
        candidateLink={decisionPopup.link}
      />
      <PopupCreateInterview
        open={interviewPopup.open}
        onOk={onSaveInterview}
        onClose={onCloseInterview}
        initialValues={interviewInitialValues}
        empty={interviewPopup.empty}
        candidateName={interviewPopup.candidateName}
        accessToCalendar={user.profile.has_calendar_access}
        userData={interviewPopup.userData}
      />
      <PopupCreateFeedback
        open={feedbackPopup.open}
        feedback={feedbackPopup.feedback}
        onSave={onSaveFeedback}
        onClose={onCloseFeedback}
        userData={feedbackPopup.userData}
      />
      <PopupCreateOffer
        open={offerPopup.open}
        onSave={onSaveOffer}
        onClose={onCloseOffer}
        userData={offerPopup.userData}
      />
      <PopupSubmitOfferDecision
        open={offerDecisionPopup.open}
        userData={offerDecisionPopup.userData}
        onClose={onCloseSubmitOfferPopup}
        onSubmit={onSubmitOfferPopup}
        isJobStarted={offerDecisionPopup.activeStep === 7}
        candidateLink={offerDecisionPopup.userData.link}
        jobId={offerDecisionPopup.jobId}
      />
    </>
  );
};

const OpeningsTableEmpty = () => (
  <div className="all-openings__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no openings here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);

const ActiveCandidatesTableEmpty = () => (
  <div className="active-candidates__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no active candidates here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
