import React from 'react';
import moment from 'moment';
import { Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import './style.scss';

export const PopupCancelInterview = ({ open, startDate, endDate, userData, onClose, onConfirm }) => {
  const date = `${moment(startDate).format('ddd MMM DD, YYYY H:mm')} – ${moment(endDate).format('H:mm')}`;
  return (
    <Popup
      className="popup-cancel-interview"
      open={open}
      title="Cancel interview"
      cancelButtonProps={{ onClick: onClose, children: 'back to Job' }}
      okButtonProps={{ onClick: onConfirm, children: 'Cancel interview' }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">{`${userData.first_name_latin} ${userData.last_name_latin}`}</Typography.Text>
        <Typography.Caption className="user-info__subtitle">{`${userData.first_name_local} ${userData.last_name_local}`}</Typography.Caption>
        <Typography.Caption className="user-info__subtitle">
          {userData.specialities?.[0]?.level} {userData.specialities?.[0]?.speciality}
        </Typography.Caption>
      </div>
      <div className="info-block">
        <Typography.Label>Date and time</Typography.Label>
        <Typography.Text>{date}</Typography.Text>
      </div>
      <Typography.Text>Are you sure you want to cancel interview?</Typography.Text>
    </Popup>
  );
};
