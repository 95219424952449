import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { notification } from '_atoms';
import { ROUTES } from 'router/constants';
import history from './history';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: params =>
    Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? `${key}=${value.join(',')}` : value ? `${key}=${encodeURIComponent(value)}` : ''
      )
      .filter(v => v)
      .join('&'),
});

export const checkToken = () => {
  const data = jwtDecode(window.localStorage.getItem('access_token'));
  return data.exp > moment().unix();
};

export async function fetchWrapper({ url, method = 'GET', body = {}, headers = {}, version = 'v1', skipTokenCheck }) {
  try {
    const token = window.localStorage.getItem('access_token');
    if (token && checkToken()) {
      headers.Authorization = `JWT ${token}`;
    } else if (!skipTokenCheck) {
      history.push(ROUTES.LOGIN);
      return;
    }

    const config = {
      method,
      url: `${version}/${url}`,
      headers,
    };

    if (method === 'GET') {
      config.params = Object.fromEntries(
        Object.entries(body).filter(([_, value]) => value !== undefined && value !== '')
      );
    } else {
      config.data = body;
    }

    const response = await api(config);

    if (response.status === 204) return null;

    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('application/json')) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    const { response } = error;

    if (response) {
      const { status, data } = response;

      if (status === 401) {
        window.localStorage.removeItem('access_token');
        history.push(ROUTES.LOGIN);
        return Promise.reject(error);
      }

      if (status === 403) {
        notification({ message: 'You do not have permission to perform this action.' });
        return Promise.reject(error);
      }

      if (status === 400) {
        if (data) {
          let errorMessage = '';
          Object.keys(data).forEach(key => {
            const item = data[key];
            errorMessage += typeof item === 'string' ? item : item.join(', ');
          });
          notification({ message: errorMessage });
        } else {
          notification({ message: 'Something went wrong. Please check your data.' });
        }
        return Promise.reject(error);
      }

      if (status === 500) {
        notification({ message: response.statusText || '500 error. Please contact your Administrator' });
        return Promise.reject(error);
      }

      try {
        return Promise.reject(data);
      } catch (e) {
        return Promise.reject(error);
      }
    }

    throw error;
  }
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
}

export function dataURItoBlob(dataURI) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export const snakeCase = string =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
