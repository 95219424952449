import moment from 'moment';
import { fetchWrapper } from 'helpers/helpers';
import history from 'helpers/history';
import { setLoading, setOpeningsLoading, setStepsLoading } from 'store/reducers/commonReducer';
import {
  setCandidatesJob,
  setJobInfo,
  setOpeningsList,
  setStepsToJob,
  clearCandidatesJob,
  setCandidatesLoader,
  setLoadingCandidateData,
  setApproversCandidate,
  setApproversCandidateLoading,
  setCVSCandidate,
  setCVSLinks,
  setScorecardCandidate,
  setInterview,
  setInterviewLoading,
  setOfferingCandidate,
  setOfferingCandidateLoading,
  setProbationPeriodData,
  setEntryDateLoading,
  setOpeningCandidate,
  setActivityList,
  setActivityListLoading,
  setRoomsOptions,
  setUserData,
  setOfferRecommendation,
  setTimelineCommentLoading,
  setTodaysnterviews,
  setInterviewFeedbacksLoading,
  setInterviewFeedbacks,
} from 'store/reducers/jobReducer';
import { ROUTES } from 'router/constants';
import { STATUSES } from 'constants/user';
import { getTimeline } from './candidateActions';

export const getListJobs = (body, version = 'v1') =>
  fetchWrapper({
    url: 'jobs/',
    body,
    version,
    trackOrder: true,
  });

export const getOpeningsCount = (body, version = 'v2') =>
  fetchWrapper({
    url: 'jobs/openings-count/',
    version,
    body,
  });

export const getOpeningsOnTheJobList = (uuid, version = 'v2') =>
  fetchWrapper({
    url: `jobs/${uuid}/openings-on-job-list/`,
    version,
  });

export const setDataJob = body =>
  fetchWrapper({
    url: `jobs/${body.uuid ? `${body.uuid}/` : ''}`,
    method: body.uuid ? 'PUT' : 'POST',
    body,
    version: 'v2',
  });

export function getOpeningsForDashboard(dashboard, url, company = '') {
  return fetchWrapper({
    url: `dashboard/${dashboard}/${url}?company=${company}`,
    method: 'GET',
  });
}

export function declineApproveOpening(uuid, openingUuid, type, body) {
  return fetchWrapper({
    url: `jobs/${uuid}/openings/${openingUuid}/${type}/`,
    method: 'POST',
    body,
  });
}

export function checkUniqJobName(name) {
  return fetchWrapper({
    url: `jobs/validate/`,
    method: 'POST',
    body: name,
  });
}

export function getJobInfo(uuid, clearData = true) {
  return dispatch => {
    if (clearData) dispatch(clearJobInfo());
    return fetchWrapper({
      url: `jobs/${uuid}/`,
      version: 'v2',
    }).then(resp => {
      dispatch(setJobInfo(resp));
      return resp;
    });
  };
}

export function clearJobInfo() {
  return dispatch => dispatch(setJobInfo({}));
}

export function assignHrToJobs(uuid, recruiters, assigned_as_recruiters) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${uuid}/`,
      method: 'PATCH',
      body: {
        recruiters,
        assigned_as_recruiters,
      },
    })
      .then(() => {
        dispatch(getJobInfo(uuid, false));
      })
      .catch(() => {});
}
export function reassignRecruiterWithActiveCandidates(body, jobId) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/unassign-recruiter/`,
      method: 'POST',
      body,
    })
      .then(resp => {
        dispatch(getJobInfo(jobId));
        return resp;
      })
      .catch(() => {});
}

export function deleteJobs(uuid) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${uuid}/`,
      method: 'DELETE',
    })
      .then(() => {
        history.push(ROUTES.JOBS);
      })
      .catch(() => {});
}

export function getSteps(id, updateOnlySteps) {
  return dispatch => {
    dispatch(setStepsLoading(true));
    if (!updateOnlySteps) {
      dispatch(clearCandidatesJob());
    }
    return fetchWrapper({
      url: `jobs/${id}/workflow/steps/`,
      version: 'v2',
    })
      .then(resp => {
        dispatch(setStepsToJob(resp));
        dispatch(setStepsLoading(false));
        return resp;
      })
      .catch(() => {
        dispatch(setStepsLoading(false));
      });
  };
}
export function reassignResponsibleRecruiter(body, activeStep, updateInterview, updateStepFullAssignment, step) {
  return dispatch =>
    fetchWrapper({
      url: `candidates/responsible-recruiters/reassign/`,
      method: 'POST',
      body,
    }).then(() => {
      fetchWrapper({
        url: `jobs/${body.job}/workflow/steps/${activeStep}/candidates/${body.candidate}/approvers/`,
      }).then(data => {
        dispatch(setApproversCandidate(data.filter(i => !i.user.is_deleted)));
      });
      dispatch(getCandidateStepAssignment(body.job, body.candidate, updateStepFullAssignment, activeStep, step));
      if (updateInterview) {
        dispatch(getInterview(body.job, activeStep, body.candidate));
        dispatch(getInterviewFeedbacksAPI(body.job, activeStep, body.candidate));
      }
      dispatch(getJobInfo(body.job));
    });
}
export function getCandidatesJobs(id, stepId, clear, body) {
  return dispatch => {
    dispatch(setCandidatesLoader({ status: body.status, value: true }));

    return fetchWrapper({
      url: `jobs/${id}/workflow/steps/${stepId}/candidate-step-assignments/`,
      version: 'v2',
      body,
    })
      .then(resp => {
        const { results, count, next } = resp;
        dispatch(setCandidatesJob({ results, count, clear, status: body.status, next }));
        dispatch(setStepsLoading(false));
        return resp;
      })
      .finally(() => {
        dispatch(setCandidatesLoader({ status: body.status, value: false }));
      });
  };
}

export function submitOffer(jobId, stepId, candidateId, data) {
  return dispatch =>
    fetchWrapper({
      method: 'POST',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/offer/`,
      body: data,
    })
      .then(() => {})
      .catch(() => {});
}

export const getOpeningsList = (id, body) =>
  fetchWrapper({
    url: `jobs/${id}/openings/`,
    body,
  });

export function getOpenings(id, body) {
  return dispatch => {
    dispatch(setOpeningsLoading(true));
    return fetchWrapper({
      url: `jobs/${id}/openings/`,
      body,
    })
      .then(resp => {
        dispatch(setOpeningsList(resp));
        return resp;
      })
      .catch(() => {})
      .finally(() => dispatch(setOpeningsLoading(false)));
  };
}

export function getOpening(id, openingUuid) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${id}/openings/${openingUuid}/`,
    })
      .then(resp => {
        dispatch(setOpeningCandidate(resp));
      })
      .catch(() => {})
      .finally(() => dispatch(setLoading(false)));
}

export const setDataOpening = (jobId, openingId, body) =>
  fetchWrapper({
    url: `jobs/${jobId}/openings/${openingId ? `${openingId}/` : ''}`,
    method: openingId ? 'PUT' : 'POST',
    body,
  });

export const getOpeningsLevelsAPI = jobId =>
  fetchWrapper({
    url: `jobs/${jobId}/opening-levels/`,
  });

export function closeOpening(uuid, openingUuid, body, filters) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${uuid}/openings/${openingUuid}/close/`,
      method: 'POST',
      body,
    })
      .then(() => {
        dispatch(getOpenings(uuid, filters));
      })
      .catch(() => {});
}

export function deleteOpening(uuid, openingUuid, filters) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${uuid}/openings/${openingUuid}/`,
      method: 'DELETE',
    })
      .then(() => {
        dispatch(getOpenings(uuid, filters));
      })
      .catch(() => {});
}

export function submitDecisionWithOppening(jobId, stepId, candidateId, body) {
  return dispatch =>
    fetchWrapper({
      method: 'POST',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/offer-decision/`,
      body,
    })
      .then(() => {})
      .catch(() => {});
}
export function exportToCSV() {
  return dispatch =>
    fetchWrapper({
      url: `dashboard/resource-manager/export/xlsx/`,
      method: 'GET',
    }).then(res => {
      const link = document.createElement('a');
      link.href = res.link;
      link.click();
    });
}

export function approveDeclineOpening(uuid, openingUuid, type, body, filter) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${uuid}/openings/${openingUuid}/${type}/`,
      method: 'POST',
      body,
    })
      .then(() => {
        dispatch(getOpenings(uuid, filter));
      })
      .catch(() => {});
}

export function getApprovers(jobId, stepId, candidateId) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/approvers/`,
    }).then(data => {
      dispatch(setApproversCandidate(data));
    });
}

export const setApprover = (jobId, stepId, candidateId, approverId) =>
  fetchWrapper({
    method: 'POST',
    url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/approvers/`,
    body: {
      step: stepId,
      candidate: candidateId,
      users: approverId,
    },
  })
    .then(() => getApprovers(jobId, stepId, candidateId))
    .catch(() => {});

export function getRoomsOption(date, fromTime, toTime) {
  const from_time = fromTime.length > 0 ? moment(fromTime, 'HH:mmZ').format('HH:mm') : '12:00';
  const to_time = toTime.length > 0 ? moment(toTime, 'HH:mmZ').format('HH:mm') : '23:30';
  return dispatch => {
    fetchWrapper({
      url: `company-rooms/`,
      body: {
        from_time,
        to_time,
        date: moment(date).format('YYYY-MM-DD'),
      },
    }).then(resp => {
      dispatch(setRoomsOptions(resp));
    });
  };
}

export const getCandidateStepAssignment = (jobId, candidateId, clearData, stepId, step) => dispatch => {
  dispatch(setLoadingCandidateData(true));
  if (clearData) {
    dispatch(setUserData({}));
    dispatch(setCVSLinks([]));
    dispatch(setCVSCandidate([]));
    dispatch(setOpeningCandidate(null));
    dispatch(setActivityList([]));
    dispatch(setOpeningCandidate(null));
    dispatch(setProbationPeriodData({}));
  }
  return fetchWrapper({
    url: `jobs/${jobId}/workflow/steps/candidate-step-assignments/candidates/${candidateId}/`,
    version: 'v2',
  })
    .then(resp => {
      if (clearData) {
        if (resp.candidate.cvs && resp.candidate.cvs.length)
          dispatch(getCVSCandidate(resp.candidate.cvs.map(cv => cv.uuid)));

        if (step === 2 || step === 3) {
          dispatch(setInterview({}));
          dispatch(getInterview(jobId, stepId, candidateId));
        }

        if (step >= 2) {
          dispatch(getInterviewFeedbacksAPI(jobId, stepId, candidateId));
        }

        if (step === 6) {
          dispatch(getOpenings(jobId, { status: 'Open' }));
          // dispatch(getProbationPeriod(jobId, stepId, candidateId));
          dispatch(setEntryDateLoading(true));
          if (resp.status !== STATUSES.REJECTED)
            dispatch(getHiredCandidate(jobId, candidateId))
              .then(r => r)
              .finally(() => {
                dispatch(setEntryDateLoading(false));
              });
        }
        if (resp.opening) dispatch(getOpening(jobId, resp.opening));
        if (resp.candidate.cvs_links)
          dispatch(
            setCVSLinks(
              resp.candidate.cvs_links.map(i => ({
                link: 'CV link',
                url: i,
              }))
            )
          );
        if (step >= 5 && !!stepId) dispatch(getOffering(jobId, stepId, candidateId));

        if (stepId) {
          dispatch(setApproversCandidateLoading(true));
          fetchWrapper({
            url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/approvers/`,
          })
            .then(data => {
              dispatch(setApproversCandidateLoading(false));
              dispatch(setApproversCandidate(data.filter(i => !i.user.is_deleted)));
            })
            .catch(() => {
              dispatch(setApproversCandidateLoading(false));
            });
        }
        // dispatch(getActivityList(jobId, candidateId));
      }
      dispatch(
        setUserData({
          ...resp.candidate,
          need_sent_decision: resp.need_sent_decision,
          responsible_recruiter: resp.responsible_recruiter,
          step_status: resp.status,
          potential_opening: resp.potential_opening,
        })
      );
      dispatch(setLoadingCandidateData(false));
    })
    .catch(() => {
      dispatch(setOpeningCandidate(null));
      dispatch(setLoadingCandidateData(false));
    });
};

export function submitDecision(jobId, stepId, candidateId, body, finalDecision) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/${
        finalDecision ? 'final-decision' : 'decisions'
      }/`,
      method: 'POST',
      body: { ...body, candidate: candidateId, step: stepId },
    });
}

export function actionWithdraw(jobId, stepId, candidateId, body) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/withdraw/`,
      method: 'POST',
      body,
    })
      .then(() => {})
      .catch(() => {});
}

function getCVSCandidate(uuid__in, preview) {
  return dispatch => {
    fetchWrapper({
      url: `files/${preview ? 'documents_preview' : 'documents'}/`,
      body: {
        uuid__in,
      },
    })
      .then(resp => {
        dispatch(setCVSCandidate(resp));
      })
      .catch(() => {});
  };
}

export const getInterviewFeedbacksDashboardAPI = (jobId, stepId, candidateId, userUuid) =>
  fetchWrapper({
    url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interview-feedbacks/`,
  })
    .then(resp => {
      const empty = resp.find(f => !f.comment && f.approver.uuid === userUuid);
      const otherFeedbacks = resp.filter(f => !!f.comment);
      return empty ? [empty, ...otherFeedbacks] : otherFeedbacks;
    })
    .catch(() => {});

export const getInterviewFeedbacksAPI = (jobId, stepId, candidateId) => (dispatch, getState) => {
  dispatch(setInterviewFeedbacksLoading(true));
  return fetchWrapper({
    url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interview-feedbacks/`,
  })
    .then(resp => {
      const userUuid = getState().authorize.user.uuid;
      const empty = resp.find(f => !f.comment && f.approver.uuid === userUuid);
      const otherFeedbacks = resp.filter(f => !!f.comment);
      const feedbacks = empty ? [empty, ...otherFeedbacks] : otherFeedbacks;

      dispatch(setInterviewFeedbacks(feedbacks));
      dispatch(setInterviewFeedbacksLoading(false));
      return feedbacks;
    })
    .catch(() => {
      dispatch(setInterviewFeedbacks([]));
      dispatch(setInterviewFeedbacksLoading(false));
    });
};

export const saveInterviewFeedbacks = (jobId, stepId, candidateId, scorecardId, body) =>
  fetchWrapper({
    method: 'PUT',
    url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interview-feedbacks/${scorecardId}/`,
    body,
  });

export function getOffering(jobId, stepId, candidateId) {
  return dispatch => {
    dispatch(setOfferingCandidateLoading(true));
    fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/offer/`,
    })
      .then(resp => {
        dispatch(setOfferingCandidate(resp.length ? resp[0] : {}));
        dispatch(setOfferingCandidateLoading(false));
      })
      .catch(() => {
        dispatch(setOfferingCandidateLoading(false));
      });
    fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/recommendation/`,
    })
      .then(resp => {
        dispatch(setOfferRecommendation(resp));
      })
      .catch(() => {
        dispatch(setOfferRecommendation(null));
      });
  };
}

export function assignToStep(jobId, stepId, candidateId, body) {
  return dispatch =>
    fetchWrapper({
      method: 'POST',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/assign-step/`,
      body,
    })
      .then(() => {})
      .catch(() => {});
}

export function getTodaysInterviewsAPI() {
  return dispatch =>
    fetchWrapper({
      url: `todays-interviews/`,
      version: 'v2',
    }).then(resp => {
      dispatch(setTodaysnterviews(resp));
    });
}

export function getInterview(jobId, stepId, candidateId) {
  return dispatch => {
    dispatch(setInterviewLoading(true));
    return fetchWrapper({
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interviews/`,
    })
      .then(resp => {
        dispatch(setInterviewLoading(false));
        dispatch(setInterview(resp[0] ? resp[0] : {}));
      })
      .catch(() => {
        dispatch(setInterviewLoading(false));
        // dispatch(setInterview({}))
      });
  };
}

export function createInterview(jobId, stepId, candidateId, body) {
  const { uuid } = body;
  delete body.uuid;
  return dispatch =>
    fetchWrapper({
      method: uuid ? 'PUT' : 'POST',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interviews/${uuid ? `${uuid}/` : ''}`,
      body,
    })
      .then(resp => {
        if (resp) dispatch(setInterview(resp));
        return resp;
      })
      .catch(() => null);
}

export function confirmInterview(jobId, stepId, candidateId, interviewId) {
  return dispatch =>
    fetchWrapper({
      method: 'POST',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interviews/confirm-interview/`,
      body: {
        interview: interviewId,
      },
    })
      .then(resp => {
        dispatch(getInterview(jobId, stepId, candidateId));
        dispatch(getInterviewFeedbacksAPI(jobId, stepId, candidateId));
        return resp;
      })
      .catch(() => null);
}

export function deleteInterview(jobId, stepId, candidateId, interviewId) {
  return dispatch =>
    fetchWrapper({
      method: 'DELETE',
      url: `jobs/${jobId}/workflow/steps/${stepId}/candidates/${candidateId}/interviews/${interviewId}/`,
    });
}

export function updateCandidateResolution(jobId, candidateId, body) {
  return dispatch => {
    dispatch(setEntryDateLoading(true));
    fetchWrapper({
      method: 'PATCH',
      url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateId}/`,
      body,
    })
      .then(() => {
        dispatch(getHiredCandidate(jobId, candidateId));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setEntryDateLoading(false));
      });
  };
}

export const getHiredCandidates = (jobId, body) =>
  fetchWrapper({
    url: `jobs/${jobId}/workflow/probation-passed/candidates/`,
    body,
  });

export const updateHiredCandidate = (jobId, candidateId, body) =>
  fetchWrapper({
    method: 'PATCH',
    url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateId}/`,
    body,
  });

export const getHiredCandidate = (jobId, candidateId) => dispatch =>
  fetchWrapper({
    method: 'GET',
    url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateId}/`,
  })
    .then(resp => {
      dispatch(setProbationPeriodData(resp));
      return resp;
    })
    .catch(() => {
      dispatch(setProbationPeriodData({}));
    });

export function getProbationPassedScorecard(jobId, candidateUuid) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateUuid}/scorecards/`,
    })
      .then(resp => {
        if (resp)
          dispatch(
            setScorecardCandidate(
              resp.map(item => ({
                ...item,
                scorecard_items: item.scorecard_items.map(v => ({ label: v.name, value: +v.value / 2, id: v.uuid })),
              }))
            )
          );
      })
      .catch(() => {});
}

export function getProbationPassedStartDate(jobId, candidateUuid) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateUuid}/start-date/`,
    })
      .then(resp => {
        if (resp) dispatch(setProbationPeriodData(resp));
        else dispatch(setProbationPeriodData({}));
      })
      .catch(() => {
        dispatch(setProbationPeriodData({}));
      });
}

export function updateProbationPassedStartDate(jobId, candidateUuid, body) {
  return dispatch =>
    fetchWrapper({
      url: `jobs/${jobId}/workflow/probation-passed/candidates/${candidateUuid}/start-date/`,
      method: 'PUT',
      body,
    })
      .then(resp => {
        if (resp) dispatch(setProbationPeriodData(resp));
      })
      .catch(() => {});
}

export function getActivityList(job, candidateUuid) {
  return dispatch => {
    dispatch(setActivityListLoading(true));
    return fetchWrapper({
      url: `candidates/${candidateUuid}/events/`,
      body: {
        job,
      },
    })
      .then(resp => {
        if (resp) {
          const list = resp.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
          dispatch(setActivityList(list));
        }
        dispatch(setActivityListLoading(false));
        return resp;
      })
      .catch(() => {
        dispatch(setActivityList([]));
        dispatch(setActivityListLoading(false));
      });
  };
}

export const changeStateJob = (uuid, body) => dispatch =>
  fetchWrapper({
    method: 'PUT',
    url: `jobs/${uuid}/status/`,
    body,
  });

export const getMentionUsers = (uuid, candidateUuid) =>
  fetchWrapper({
    method: 'GET',
    url: `jobs/${uuid}/workflow/candidates/${candidateUuid}/comments/mention-users/`,
  });

export const postCommentTimeline = (comment, recipients, jobUuid, candidateUuid) => dispatch => {
  dispatch(setTimelineCommentLoading(true));
  return fetchWrapper({
    method: 'POST',
    url: `jobs/${jobUuid}/workflow/candidates/${candidateUuid}/comments/`,
    body: { comment, recipients },
  })
    .then(() => {
      dispatch(getTimeline(candidateUuid, jobUuid, true));
    })
    .finally(() => {
      dispatch(setTimelineCommentLoading(false));
    });
};

export const deleteCommentTimeline = (commentUuid, jobUuid, candidateUuid) =>
  fetchWrapper({
    method: 'DELETE',
    url: `jobs/${jobUuid}/workflow/candidates/${candidateUuid}/comments/${commentUuid}/`,
  });

export function getOpeningById(id, openingUuid) {
  return fetchWrapper({
    url: `jobs/${id}/openings/${openingUuid}/`,
  });
}
