import React from 'react';
import moment from 'moment';
import { Popup } from '_atoms';
import { Typography } from '_atoms/Typography';
import './style.scss';

export const PopupConfirmInterview = ({ open, startDate, endDate, userData, onClose, onConfirm }) => {
  const date = `${moment(startDate).format('ddd MMM DD, YYYY H:mm')} – ${moment(endDate).format('H:mm')}`;
  return (
    <Popup
      className="popup-confirm-interview"
      open={open}
      title="Confirm interview"
      cancelButtonProps={{ onClick: onClose, children: 'Cancel' }}
      okButtonProps={{ onClick: onConfirm, children: 'Confirm interview' }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">{`${userData.first_name_latin} ${userData.last_name_latin}`}</Typography.Text>
        <Typography.Caption className="user-info__subtitle">{`${userData.first_name_local} ${userData.last_name_local}`}</Typography.Caption>
      </div>
      <div className="info-block">
        <Typography.Label>Date and time</Typography.Label>
        <Typography.Text>{date}</Typography.Text>
      </div>
      <Typography.Text>Are you sure you want to confirm interview started?</Typography.Text>
    </Popup>
  );
};
