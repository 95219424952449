import React from 'react';
import { RichText, Status, Tooltip } from '_atoms';
import { getJobStatusColor } from '_templates/Candidates/utils';
import { headerCellRender } from '_templates/Jobs/constants';
import { STATUSES } from '../../constants/user';

export const COLUMNS = (sort, setSorting) => [
  {
    title: headerCellRender('Job name', 'Job', 'job', sort, setSorting),
    dataIndex: 'job',
    key: 'job',
    width: 246,
    fixed: 'left',
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: headerCellRender('Candidate', 'Candidate name', 'candidate_full_name', sort, setSorting),
    dataIndex: 'candidate_full_name',
    key: 'candidate_full_name',
    width: 168,
    fixed: 'left',
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: headerCellRender('Country', 'Country', 'country', sort, setSorting),
    dataIndex: 'country',
    key: 'country',
    width: 168,
    sorter: true,
    render: data => (data ? <Tooltip label={data}>{data}</Tooltip> : '—'),
  },
  {
    title: 'Recruiter',
    dataIndex: 'responsible_recruiter',
    key: 'responsible_recruiter',
    width: 168,
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: 'Step',
    dataIndex: 'step',
    key: 'step',
    width: 220,
    sorter: true,
    render: (data, { candidate_status }) =>
      [STATUSES.HIRED, STATUSES.FIRED].includes(candidate_status) ? '—' : <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: 'Step status',
    dataIndex: 'step_status',
    key: 'step_status',
    width: 220,
    sorter: true,
    render: (status, { candidate_status }) =>
      [STATUSES.HIRED, STATUSES.FIRED].includes(candidate_status) ? (
        '—'
      ) : (
        <Status type={getJobStatusColor(status)}>{status}</Status>
      ),
  },
  {
    title: headerCellRender('Assignment date', 'Assignment date', 'assignment_date', sort, setSorting),
    dataIndex: 'assignment_date',
    key: 'assignment_date',
    width: 168,
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: 'Interviews',
    dataIndex: 'interviews',
    key: 'interviews',
    width: 168,
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
  {
    title: 'Assignment comment',
    dataIndex: 'comment',
    key: 'comment',
    width: 168,
    sorter: true,
    render: data => (
      <Tooltip label={<RichText text={data} />}>
        <div className="plain-rich-text">
          <RichText text={data} />
        </div>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Job offer', 'Job offer', 'has_offer', sort, setSorting),
    dataIndex: 'has_offer',
    key: 'has_offer',
    width: 168,
    sorter: true,
    render: ({ className, data }) => (
      <Tooltip label={data}>
        <span className={className}>{data}</span>
      </Tooltip>
    ),
  },
  {
    title: headerCellRender('Offer feedback', 'Offer feedback', 'offer_decision', sort, setSorting),
    dataIndex: 'offer_decision',
    key: 'offer_decision',
    width: 168,
    sorter: true,
    render: ({ className, data }) => (
      <Tooltip label={data}>
        <span className={className}>{data}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
    width: 264,
    sorter: true,
    render: data => <Tooltip label={data}>{data}</Tooltip>,
  },
];

export const OPTIONS = {
  sorting: { key: 'job', order: 'asc', field: 'Job' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};
