import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'antd/dist/antd.less';
import { Router as Routes } from 'router';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import history from './helpers/history';
import ErrorBoundary from './helpers/ErrorBoundary';
// import * as Sentry from '@sentry/browser';
import { AuthCheck } from './features/auth';
import InterviewsCheck from './features/interview/InterviewsCheck';
import { ability, AbilityContext } from './permission';
import 'styles/global.scss';

// Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});

const onScriptLoadError = () => {
  console.log('google library in not loaded');
};

render(
  <Provider store={store}>
    <Router history={history}>
      <AbilityContext.Provider value={ability}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GA_CLIENT_ID} onScriptLoadError={onScriptLoadError}>
          <ErrorBoundary>
            <AuthCheck>
              <InterviewsCheck />
              <Routes />
            </AuthCheck>
          </ErrorBoundary>
        </GoogleOAuthProvider>
      </AbilityContext.Provider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
