import { fetchWrapper } from 'helpers/helpers';
import { setCandidateLoading, setPositions } from 'store/reducers/commonReducer';
import {
  setCandidateJobsListLoading,
  setCompanies,
  setJobsList,
  setRemindersList,
  setSpecialties,
  setTags,
  setCountries,
  setResponsibleRecruiter,
  setCandidateData,
  setCandidateDocuments,
  setCandidateTimeline,
  setDuplicateCandidates,
  setSources,
} from 'store/reducers/candidateReducer';
import { setTimelineLoading } from 'store/reducers/jobReducer';

export function getCountries() {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/countries/',
    })
      .then(resp => {
        dispatch(setCountries(resp));
      })
      .catch(() => {});
}

export function getSpecialties() {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/specialities/',
    })
      .then(resp => {
        dispatch(setSpecialties(resp));
      })
      .catch(() => {});
}

export function getCompanies(body) {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/companies/',
      body,
    })
      .then(resp => {
        dispatch(setCompanies(body.limit ? resp.results : resp));
      })
      .catch(() => {
        dispatch(setCompanies([]));
      });
}

export function getPositions() {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/positions/',
    })
      .then(resp => {
        dispatch(setPositions(resp));
      })
      .catch(() => {});
}

export function getTags() {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/tags/',
    })
      .then(resp => {
        dispatch(setTags(resp));
      })
      .catch(() => {});
}

export function saveCandidate(data, id, isSSC) {
  return dispatch =>
    fetchWrapper({
      method: id ? 'PUT' : 'POST',
      version: 'v2',
      url: `${isSSC ? 'self-submitted-' : ''}candidates/${id ? `${id}/` : ''}`,
      body: isSSC ? { candidate_profile: data } : data,
    })
      .then(resp => resp)
      .catch(() => {
        dispatch(setCandidateLoading(false));
      });
}

export function getReminders(candidate) {
  return dispatch =>
    fetchWrapper({
      method: 'GET',
      url: `candidates/${candidate}/reminders/`,
    }).then(resp => {
      dispatch(setRemindersList(resp));
    });
}

export function getJobs(candidate) {
  return dispatch => {
    dispatch(setCandidateJobsListLoading(true));
    return fetchWrapper({
      method: 'GET',
      url: `candidates/${candidate}/jobs/`,
    })
      .then(resp => {
        dispatch(setJobsList(resp));
        return resp;
      })
      .finally(() => dispatch(setCandidateJobsListLoading(false)));
  };
}
export function getResponsibleRecruiter() {
  return dispatch =>
    fetchWrapper({
      method: 'GET',
      url: 'candidates/responsible-recruiters/',
    }).then(resp => {
      dispatch(setResponsibleRecruiter(resp));
    });
}

export function editReminder(data, id, candidate) {
  return dispatch =>
    fetchWrapper({
      method: id ? 'PUT' : 'POST',
      url: `candidates/${candidate}/reminders/${id ? `${id}/` : ''}`,
      body: data,
    }).then(() => {
      dispatch(getReminders(candidate));
    });
}

export function deleteReminder(id, candidate) {
  return dispatch =>
    fetchWrapper({
      method: 'DELETE',
      url: `candidates/${candidate}/reminders/${id}/`,
    }).then(() => {
      dispatch(getReminders(candidate));
    });
}

export function deleteInteraction(id, candidate) {
  return dispatch =>
    fetchWrapper({
      method: 'DELETE',
      url: `candidates/${candidate}/interactions/${id}/`,
    }).then(() => {
      dispatch(getTimeline(candidate, undefined, true));
    });
}

export function completeReminder(data, candidate, comment) {
  return dispatch =>
    Promise.all([
      fetchWrapper({
        method: 'PATCH',
        url: `candidates/${candidate}/reminders/${data.uuid}/`,
        body: { in_progress: false },
      }),
      fetchWrapper({
        method: 'POST',
        url: `candidates/${candidate}/interactions/`,
        body: {
          candidate,
          comment,
          datetime: data.datetime,
          hr: data.author.uuid,
          in_progress: true,
          type: data.type,
          recruiter: data.recruiter.uuid,
        },
      }),
    ]).then(() => {
      dispatch(getTimeline(candidate, undefined, true));
      dispatch(getReminders(candidate));
    });
}

export function addInteractionAPI(data, id, candidate) {
  return dispatch =>
    fetchWrapper({
      method: id ? 'PUT' : 'POST',
      url: `candidates/${candidate}/interactions/${id ? `${id}/` : ''}`,
      body: data,
    }).then(() => {
      dispatch(getTimeline(candidate, undefined, true));
    });
}

export function getListCandidates(body, version = 'v1') {
  return fetchWrapper({
    url: 'candidates/',
    body,
    version,
  });
}

export function checkDuplicate(body) {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/similars/',
      body,
    }).then(resp => {
      dispatch(setDuplicateCandidates(resp));
      return resp;
    });
}

export function getCandidateInfo(uuid, selfSubmitted, preview, getDocs = true) {
  return dispatch => {
    dispatch(setCandidateLoading(true));
    return fetchWrapper({
      version: selfSubmitted || preview ? 'v2' : 'v1',
      url: `${!preview && selfSubmitted ? 'self-submitted-' : ''}candidates/${uuid}/${preview ? 'preview/' : ''}`,
    })
      .then(resp => {
        const data = !preview && selfSubmitted ? resp.candidate_profile : resp;
        dispatch(setCandidateData(data));
        if (getDocs && data.cvs?.length) dispatch(getDocuments(data.cvs, preview));
        dispatch(setCandidateLoading(false));
        return data;
      })
      .catch(() => {
        dispatch(setCandidateLoading(false));
      });
  };
}

export function getSSCPotentialDuplicatesAPI(uuid, version = 'v2') {
  return fetchWrapper({
    method: 'GET',
    version,
    url: `self-submitted-candidates/${uuid}/potential-duplicates/`,
  });
}

export function reviewCandidate(uuid, version = 'v2') {
  return fetchWrapper({
    method: 'POST',
    version,
    url: `self-submitted-candidates/${uuid}/mark-as-reviewed/`,
  });
}

export function markCandidateAsViewed(uuid, version = 'v2') {
  return fetchWrapper({
    method: 'PATCH',
    version,
    url: `self-submitted-candidates/${uuid}/mark-as-viewed/`,
  });
}

export function bulkDeleteSSCAPI(role, uuids, version = 'v2') {
  return fetchWrapper({
    method: 'POST',
    version,
    url: `dashboard/${role}/self-submitted-candidates/bulk-deletion/`,
    body: {
      uuids,
    },
  });
}

export async function uploadDocument(body) {
  return await fetchWrapper(
    {
      method: 'POST',
      url: 'files/documents/',
      body,
    },
    true
  ).then(async data => await data);
}
export function uploadPdfCv(body) {
  return fetchWrapper(
    {
      method: 'POST',
      url: 'candidates/import/resume/',
      body,
    },
    true
  );
}

export function getDocuments(uuid__in, preview) {
  return dispatch =>
    fetchWrapper({
      url: `files/${preview ? 'documents_preview' : 'documents'}/`,
      body: {
        uuid__in,
      },
    }).then(resp => {
      dispatch(setCandidateDocuments(resp));
      return resp;
    });
}

export const getDocumentsByUUID = uuid__in =>
  fetchWrapper({
    url: `files/documents/`,
    body: {
      uuid__in,
    },
  });

export function deleteCandidate(id, isSSC) {
  return fetchWrapper({
    method: 'DELETE',
    version: isSSC ? 'v2' : 'v1',
    url: `${isSSC ? 'self-submitted-' : ''}candidates/${id}/`,
  });
}

export function getTimeline(candidateUuid, jobUuid, clearTimeline) {
  return (dispatch, getState) => {
    const { offset, sorting, filter } = getState().candidate.timeline;
    if (clearTimeline) dispatch(setCandidateTimeline({ clear: true }));
    dispatch(setTimelineLoading(true));
    fetchWrapper({
      url: `candidates/${candidateUuid}/${jobUuid ? 'events' : 'timeline'}/`,
      body: {
        job: jobUuid,
        limit: 10,
        offset: clearTimeline ? 0 : offset,
        sorting: jobUuid ? undefined : sorting,
        ordering: jobUuid ? (sorting === 'asc' ? 'created' : '-created') : undefined,
        filter: jobUuid ? undefined : filter,
      },
    })
      .then(resp => {
        const data = resp.results || resp;
        dispatch(setCandidateTimeline({ data, count: resp.count }));
      })
      .finally(() => {
        dispatch(setTimelineLoading(false));
      });
  };
}

export function setStepToCandidate(id, body) {
  return fetchWrapper({
    method: 'POST',
    url: `jobs/${id}/workflow/steps/${body.step}/candidates/`,
    body,
  });
}

export function setCandidateBlacklist(id, body) {
  return fetchWrapper({
    method: 'PATCH',
    url: `candidates/${id}/blacklist/`,
    body,
  });
}

export function setCandidateMarkAsFired(id, jobId, body) {
  return fetchWrapper({
    method: 'POST',
    url: `candidates/${id}/fire/${jobId}/`,
    body,
  });
}

export function getCandidateData(uuid, isSSC) {
  return fetchWrapper({
    url: `${isSSC ? 'self-submitted-' : ''}candidates/${uuid}/`,
    version: isSSC ? 'v2' : 'v1',
  });
}

export function candidateBlacklistAPI(id, body) {
  return fetchWrapper({
    method: 'POST',
    version: 'v2',
    url: `self-submitted-candidates/${id}/blacklist/`,
    body,
  });
}

export function importCandidateByUrl(url) {
  return fetchWrapper({
    url: 'candidates/import/',
    method: 'POST',
    body: { url },
  });
}

export function getCandidateSourcesAPI() {
  return dispatch =>
    fetchWrapper({
      url: 'candidates/sources/',
    }).then(sources => {
      dispatch(
        setSources([
          ...sources.map(s => ({ value: s.source, label: s.source })),
          { value: 'recommendations', label: 'recommendations' },
          { value: 'other', label: 'other' },
        ])
      );
    });
}

export const setMergeProfilesAPI = (uuid, body) =>
  fetchWrapper({
    url: `candidates/${uuid}/merge-profiles/`,
    version: 'v2',
    body,
    method: 'PUT',
  });
