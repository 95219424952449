import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Divider } from 'antd';
import { AtsSkeleton, Button, BUTTON_TYPES } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { getInterviewFeedbacksAPI, saveInterviewFeedbacks } from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getInterviewFeedbacks, getInterviewFeedbacksLoading } from 'store/selectors/jobSelectors';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { FeedbackCreation } from './FeedbackCreation';
import { Feedback } from './Feedback';
import { formValuesMapper } from './utils';
import './style.scss';

export const InterviewFeedback = ({ isTechnical, defaultOpen, stepId }) => {
  const dispatch = useDispatch();

  const { id: jobId, candidate: candidateId } = useParams();
  const currentUser = useSelector(getUser);
  const feedbacks = useSelector(getInterviewFeedbacks);
  const loading = useSelector(getInterviewFeedbacksLoading);

  const [openDetails, setOpenDetails] = useState(defaultOpen);
  const [showAllFeedbacks, setShowAllFeedbacks] = useState(false);
  const [editUuid, setEditUuid] = useState('');

  const onSaveScorecard = values => {
    const scorecardUuid = feedbacks.find(f => !f.comment)?.uuid || editUuid;
    saveInterviewFeedbacks(jobId, stepId, candidateId, scorecardUuid, formValuesMapper(values)).then(() => {
      setEditUuid('');
      dispatch(getInterviewFeedbacksAPI(jobId, stepId, candidateId));
    });
  };

  const onOpenDetails = () => setOpenDetails(o => !o);
  const onChangeAllFeedbacks = () => setShowAllFeedbacks(o => !o);
  const onEditCancel = () => setEditUuid('');

  const onPostFeedback = ({ uuid, ...feedback }) => {
    const data = Object.keys(feedback).length
      ? { ...feedback, is_draft: false }
      : {
          is_draft: true,
          comment: '',
          positive: null,
          english_level: null,
          level: null,
        };
    saveInterviewFeedbacks(jobId, stepId, candidateId, uuid, data).then(() => {
      dispatch(getInterviewFeedbacksAPI(jobId, stepId, candidateId));
    });
  };

  if (loading) {
    return (
      <div className="job-interview-feedback">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {isTechnical ? 'Technical' : 'Management'} interview feedback
        </Typography.Title2>
        <AtsSkeleton height={36} />
        <Divider />
      </div>
    );
  }

  if (!feedbacks.length) return null;

  return (
    <div className={cn('job-interview-feedback', { open: openDetails })}>
      <div className="job-interview-feedback__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {isTechnical ? 'Technical' : 'Management'} interview feedback
        </Typography.Title2>
        <Arrow className="open-details" onClick={onOpenDetails} />
      </div>
      <div className="job-interview-feedback__content">
        {feedbacks.slice(0, showAllFeedbacks ? feedbacks.length : 1).map(f =>
          (!f.comment && f.approver.uuid === currentUser.uuid) || f.uuid === editUuid ? (
            <FeedbackCreation
              key={f.uuid}
              approver={f.approver}
              initialValues={{
                is_draft: false,
                comment_initial: f.comment,
                comment: f.comment,
                positive: f.positive,
                english_level: f.english_level ? { value: f.english_level, label: f.english_level } : undefined,
                level: f.level ? { value: f.level, label: f.level } : undefined,
                is_edit: f.uuid === editUuid,
              }}
              onCancel={f.uuid === editUuid && onEditCancel}
              onFinish={onSaveScorecard}
            />
          ) : (
            <Feedback
              key={f.uuid}
              feedback={f}
              onEdit={() => setEditUuid(f.uuid)}
              onPostFeedback={onPostFeedback}
              showEdit={f.approver.uuid === currentUser.uuid}
            />
          )
        )}
        {feedbacks.length > 1 && (
          <Button
            className={cn('all-feedbacks', { open: showAllFeedbacks })}
            type={BUTTON_TYPES.TEXT}
            onClick={onChangeAllFeedbacks}
          >
            {showAllFeedbacks ? 'Show less' : 'Show more'} feedbacks
            <Arrow />
          </Button>
        )}
      </div>
      <Divider />
    </div>
  );
};
