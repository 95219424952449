import { levelOptions, levels } from 'constants/constants';

export const DECISION_OPTIONS = [
  { value: true, label: 'approve' },
  { value: false, label: 'reject' },
];

export const ENG_LEVEL_OPTIONS = [
  { value: 'n/a', label: 'n/a' },
  ...levelOptions.map(value => ({ value, label: value })),
];

export const LEVEL_OPTIONS = [{ value: 'n/a', label: 'n/a' }, ...levels.map(value => ({ value, label: value }))];

export const EXPERIENCE_LEVEL_OPTIONS = [
  { value: 'n/a', label: 'n/a' },
  { value: 'Trainee', label: 'Trainee' },
  { value: 'Entry', label: 'Entry' },
  { value: 'Junior -', label: 'Junior -' },
  { value: 'Junior', label: 'Junior' },
  { value: 'Junior +', label: 'Junior +' },
  { value: 'Middle -', label: 'Middle -' },
  { value: 'Middle', label: 'Middle' },
  { value: 'Middle +', label: 'Middle +' },
  { value: 'Senior -', label: 'Senior -' },
  { value: 'Senior', label: 'Senior' },
  { value: 'Senior +', label: 'Senior +' },
  { value: 'Architect -', label: 'Architect -' },
  { value: 'Architect', label: 'Architect' },
  { value: 'Architect +', label: 'Architect +' },
  { value: 'Team Lead', label: 'Team Lead' },
  { value: 'Tech Lead -', label: 'Tech Lead -' },
  { value: 'Tech Lead', label: 'Tech Lead' },
  { value: 'Tech Lead +', label: 'Tech Lead +' },
];

export const REQUIRED_RULE = { required: true, message: 'This field is required' };
export const SOURCE_RULE = {
  validator: (_, value) => {
    if (value.length < 1) {
      return Promise.reject(new Error('This field is required'));
    }
    const regex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>_\-~`+=\/\\[\] ]*$/;
    return regex.test(value) ? Promise.resolve() : Promise.reject(new Error('Contains invalid characters'));
  },
};
