import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { notification, NOTIFICATION_TYPES } from '_atoms';
import { getTodaysInterviews } from 'store/selectors/jobSelectors';
import { setTodaysnterviews } from 'store/reducers/jobReducer';
import { getTodaysInterviewsAPI } from 'actions';

const InterviewsCheck = () => {
  const dispatch = useDispatch();

  const interviews = useSelector(getTodaysInterviews);

  const loadInterviews = () => {
    dispatch(getTodaysInterviewsAPI());
  };

  useEffect(() => {
    loadInterviews();
    const interval = setInterval(loadInterviews, 6 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const checkInterviews = () => {
      const now = moment();
      interviews.forEach(interview => {
        const start = moment(interview.interview_start_datetime);
        if (!start.diff(now, 'minute')) {
          dispatch(setTodaysnterviews(interviews.filter(i => i.uuid !== interview.uuid)));
          notification({
            message: (
              <>
                It&#39;s time for the interview with{' '}
                <a
                  href={`/jobs/${interview.job}/show/steps/${interview.step}/${interview.candidate.uuid}`}
                  onClick={() => {}}
                >
                  {interview.candidate.full_name}
                </a>{' '}
                to start. Please confirm it
              </>
            ),
            type: NOTIFICATION_TYPES.SUCCESS,
            duration: 9999999,
            onClick: e => {
              if (e.target.tagName.toLowerCase() === 'a')
                e.target.closest('.ant-notification').querySelector('.ant-notification-notice-close').click();
            },
          });
        }
      });
    };

    const checkInterval = setInterval(checkInterviews, 60 * 1000);
    return () => clearInterval(checkInterval);
  }, [interviews]);

  return null;
};

export default InterviewsCheck;
