import { setCurrentUserData } from 'store/reducers/authorizeReducer';
import { ROUTES } from 'router/constants';
import { fetchWrapper } from '../helpers/helpers';
import history from '../helpers/history';
import { MixpanelService } from '../helpers/Mixpanel';

const mixpanel = new MixpanelService();

const onAfterLogin = () => {
  if (window.localStorage.getItem('link_to_page')) {
    const pathname = window.localStorage.getItem('link_to_page');
    window.localStorage.removeItem('link_to_page');
    history.push(pathname);
  } else {
    history.push(ROUTES.PORTAL);
  }
};

const onAfterCalendarConnect =
  (dispatch, uuid) =>
  ({ code }) => {
    fetchWrapper({
      method: 'POST',
      url: 'auth/token/',
      body: { code },
      skipTokenCheck: true,
    }).then(() => {
      fetchWrapper({
        method: 'PATCH',
        url: `users/${uuid}/profile/`,
        body: { has_calendar_access: true },
      }).then(() => {
        fetchWrapper({
          url: 'users/current/',
        }).then(r => {
          dispatch(setCurrentUserData(r));
          onAfterLogin();
        });
      });
    });
  };

const onErrorCalendarConnect = (dispatch, uuid) => err => {
  if (err.error === 'access_denied') {
    fetchWrapper({
      method: 'PATCH',
      url: `users/${uuid}/profile/`,
      body: { has_calendar_access: false },
    }).then(() => {
      fetchWrapper({
        url: 'users/current/',
      }).then(r => {
        dispatch(setCurrentUserData(r));
        onAfterLogin();
      });
    });
  } else {
    onAfterLogin();
  }
};

export function signIn(code, callback) {
  return dispatch =>
    fetchWrapper({
      method: 'POST',
      url: 'auth/token/',
      body: { code },
      skipTokenCheck: true,
    })
      .then(payload => {
        if (payload.token) {
          window.localStorage.setItem('access_token', payload.token);
          fetchWrapper({
            url: 'users/current/',
          })
            .then(resp => {
              if (!resp.roles.length) {
                window.localStorage.removeItem('access_token');
                callback('error');
                return;
              }
              dispatch(setCurrentUserData(resp));
              mixpanel.login(resp);
              if (resp.profile?.has_calendar_access === null || !resp.has_refresh_token) {
                window.google.accounts.oauth2
                  .initCodeClient({
                    client_id: process.env.REACT_APP_GA_CLIENT_ID,
                    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
                    callback: onAfterCalendarConnect(dispatch, resp.uuid),
                    error_callback: onErrorCalendarConnect(dispatch, resp.uuid),
                  })
                  .requestCode();
              } else {
                onAfterLogin();
              }
            })
            .catch(() => {
              history.push(ROUTES.LOGIN);
            });
        } else {
          return payload;
        }
      })
      .catch(err => {
        if (err.detail === 'User with provided email is not found') {
          callback('errorEmail');
        } else {
          callback('error');
        }
      });
}

export function signOut() {
  fetchWrapper({
    method: 'POST',
    url: 'auth/logout/',
  })
    .then(() => {
      window.localStorage.removeItem('access_token');
      history.push(ROUTES.LOGIN);
      mixpanel.logout();
    })
    .catch(() => {
      window.localStorage.removeItem('access_token');
      history.push(ROUTES.LOGIN);
      mixpanel.logout();
    });
}

export function getUserData() {
  return dispatch =>
    fetchWrapper({
      url: 'users/current/',
    })
      .then(payload => {
        if (!payload.roles.length) {
          window.localStorage.removeItem('access_token');
          history.push(ROUTES.LOGIN, { error: 'error' });
        }
        dispatch(setCurrentUserData(payload));
        mixpanel.identify(payload);
      })
      .catch(() => {
        history.push(ROUTES.LOGIN);
      });
}
