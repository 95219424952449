import React, { useEffect, useState } from 'react';
import { subject } from '@casl/ability';
import { useParams } from 'react-router-dom';
import { Card, AtsSkeleton, Typography, TYPOGRAPHY_WEIGHT } from '_atoms';
import {
  Approvers,
  PopupSubmitDecision,
  PopupSubmitOfferDecision,
  PopupWithdraw,
  PopupAssignToStep,
  PopupEditApprovers,
  JobCandidateInfo,
} from '_molecules';
import { Interview, InterviewFeedback, Resolution, Offer, Timeline } from '_organisms/index';
import { ACTIONS, UI, APPROVERS_ROLES, useAbility } from 'permission';
import { STEPS_LABELS } from 'constants/constants';
import { setApprover } from 'actions';
import img from 'dist/emptyStates/stepsNoCandidates.svg';
import './styles.scss';

export const StepsRightPanel = props => {
  const {
    openingCandidate,
    activeStep,
    createInterview,
    confirmInterview,
    submitDecisionWithOppening,
    openingsList,
    step,
    submitOffer,
    assignToStep,
    steps,
    empty,
    author,
    userData,
    approversCandidate,
    jobData,
    submitDecision,
    updateData,
    rooms,
    recruiter,
    actionWithdraw,
    cancelInterview,
    loading,
  } = props;

  const { id: jobId, step: stepId, candidate: candidateId } = useParams();

  const [showPopupDecision, setShowPopupDecision] = useState(false);
  const [showPopupWithdraw, setShowPopupWithdraw] = useState(false);
  const [showPopupApprovers, setShowPopupApprovers] = useState(false);
  const [showPopupFinalDecision, setShowPopupFinalDecision] = useState(false);
  const [showPopupAssignToStep, setShowPopupAssignToStep] = useState(false);
  const [submitDecisionOffer, setSubmitDecisionOffer] = useState(false);
  const ability = useAbility();
  const rejected = userData.step_status === 'Rejected';

  const onCLoseWithdrawPopup = () => setShowPopupWithdraw(false);
  const onWithdraw = comment => {
    setShowPopupWithdraw(false);
    actionWithdraw(comment);
  };

  const onCloseSubmitOfferPopup = () => setSubmitDecisionOffer(false);
  const onSubmitOfferPopup = data => {
    setSubmitDecisionOffer(false);
    submitDecisionWithOppening(data);
  };

  useEffect(() => {
    if (candidateId && !empty) {
      props.getCandidateStepAssignment(jobId, candidateId, true, stepId, activeStep);
    }
  }, [candidateId, empty]);

  const closePopups = () => {
    setShowPopupDecision(false);
    setShowPopupFinalDecision(false);
  };

  let offer;
  if (step) {
    if (step.name === STEPS_LABELS.OFFER) offer = !!Object.keys(props.offeringCandidate).length;
    if (step.name === STEPS_LABELS.JOB_STARTED) offer = true;
  }
  const newOpeningsList = [...openingsList];
  if (openingCandidate) newOpeningsList.push(openingCandidate);

  const defaultApprover = !!approversCandidate?.find(i => i.user.uuid === author.uuid && i.is_default);

  const onSubmitDecision = (m, d) => {
    submitDecision(m, d);
    closePopups();
  };

  if (loading) {
    return <LoadingState />;
  }
  if (empty) {
    return <EmptyState />;
  }
  const editOffer = ability.can(ACTIONS.READ, subject(UI.SUBMIT_OFFER, { step: step?.name })) && !rejected;

  return (
    <div>
      <Card className="right-panel">
        <JobCandidateInfo
          userData={userData}
          recruiter={recruiter}
          author={author}
          updateData={updateData}
          setShowPopupDecision={setShowPopupDecision}
          setShowPopupWithdraw={setShowPopupWithdraw}
          openAddApprovers={() => setShowPopupApprovers(true)}
          setShowPopupFinalDecision={setShowPopupFinalDecision}
          setShowPopupAssignToStep={setShowPopupAssignToStep}
          setSubmitDecisionOffer={setSubmitDecisionOffer}
          offer={offer}
          step={step}
          rejected={rejected}
          jobStatus={jobData.status}
        />
        {activeStep >= 6 && !rejected && (
          <Resolution
            onSubmit={props.updateCandidateResolution}
            loading={props.entryDateLoading}
            probationPeriod={props.probationPeriod}
            jobName={jobData.name}
            jobStatus={jobData.status}
          />
        )}
        {activeStep >= 5 && (
          <Offer
            onSubmit={submitOffer}
            offer={props.offeringCandidate}
            editable={editOffer}
            defaultOpen={activeStep === 5}
            loading={props.offeringCandidateLoading}
          />
        )}
        {(activeStep === 2 || activeStep === 3) && (
          <Interview
            accessToGoogleCalendar={props.user.profile.has_calendar_access}
            rooms={rooms}
            cancelInterview={cancelInterview}
            isTechnical={activeStep === 2}
            rejected={rejected}
            createInterview={createInterview}
            confirmInterview={confirmInterview}
            userData={userData}
          />
        )}
        {activeStep >= 3 && <InterviewFeedback defaultOpen={activeStep === 3} stepId={steps[3].uuid} />}
        {activeStep >= 2 && <InterviewFeedback isTechnical defaultOpen={activeStep === 2} stepId={steps[2].uuid} />}
        <Approvers
          list={approversCandidate}
          loading={props.approversCandidateLoading}
          requestOptions={{
            role: APPROVERS_ROLES.join(','),
            service: jobData.service?.uuid,
            company: jobData.company?.uuid,
            limit: 25,
          }}
          defaultApprover={defaultApprover}
          responsibleRecruiter={userData.responsible_recruiter}
          rejected={rejected || step.ordering_number >= 5}
        />
      </Card>
      <Timeline candidateData={{}} hrManagers={[]} isActivityFeed />
      <PopupWithdraw
        open={showPopupWithdraw}
        userData={userData}
        onClose={onCLoseWithdrawPopup}
        onWithdraw={onWithdraw}
      />
      <PopupSubmitOfferDecision
        open={submitDecisionOffer}
        userData={userData}
        onClose={onCloseSubmitOfferPopup}
        onSubmit={onSubmitOfferPopup}
        isJobStarted={activeStep === 6}
      />
      <PopupSubmitDecision
        onSave={onSubmitDecision}
        open={showPopupDecision || showPopupFinalDecision}
        isFinalDecision={showPopupFinalDecision}
        userData={userData}
        onClose={closePopups}
        activeStep={activeStep}
      />
      <PopupAssignToStep
        save={s => {
          setShowPopupAssignToStep(false);
          assignToStep(s);
        }}
        stepsOptions={steps.filter(i => i.ordering_number !== 6)}
        open={showPopupAssignToStep}
        userData={userData}
        onClose={() => {
          setShowPopupAssignToStep(false);
        }}
      />
      <PopupEditApprovers
        open={showPopupApprovers}
        onClose={() => setShowPopupApprovers(false)}
        onSave={approversId => {
          setApprover(jobId, stepId, candidateId, approversId)
            .then(() => {
              props.getSteps(jobId, true);
              props.getApprovers(jobId, stepId, candidateId);
            })
            .finally(() => setShowPopupApprovers(false));
        }}
        approvers={approversCandidate}
        responsibleRecruiter={userData.responsible_recruiter}
        requestOptions={{
          role: APPROVERS_ROLES.join(','),
          service: jobData.service?.uuid,
          company: jobData.company?.uuid,
          limit: 25,
        }}
      />
    </div>
  );
};

const LoadingState = () => (
  <Card className="right-panel">
    <div style={{ marginBottom: '12px' }}>
      <AtsSkeleton height={90} />
    </div>
    <div style={{ marginBottom: '12px' }}>
      <AtsSkeleton height={340} />
    </div>

    <div style={{ marginBottom: '12px' }}>
      <AtsSkeleton height={137} />
    </div>
  </Card>
);

const EmptyState = () => (
  <Card className="right-panel right-panel__empty">
    <img src={img} alt="" />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>No candidate selected</Typography.Title3>
    <Typography.Text>Select a candidate from the list on the left to view it here.</Typography.Text>
  </Card>
);
