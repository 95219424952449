import { fetchWrapper } from '../helpers/helpers';
import {
  AnalyticsFilterHelper,
  passingStepHelper,
  rejectionNumberHelper,
  rejectionReasonHelper,
  transitionStepHelper,
} from '../_templates/AnalyticsDashboard/helpers';

export const analyticsFiltersMapper = ({ search, ...body }) => ({
  candidates: body.candidates.map(s => s.value),
  jobs: body.jobs.map(s => s.value),
  offices: body.offices.map(s => s.value),
  openings_statuses: body.openings_statuses.map(s => s.value),
  recruiters: body.recruiters.map(s => s.value),
  team_leads: body.team_leads.map(s => s.value),
  companies: body.companies.map(s => s.value),
  services: body.services.map(s => s.value),
  from_date: body.period[0].format('YYYY-MM-DD'),
  to_date: body.period[1].format('YYYY-MM-DD'),
  search,
});

export const analyticsFilter = (url, body, search = '', withoutModifications) =>
  fetchWrapper({
    url: `analytics/filters/${url}/`,
    method: 'POST',
    body: analyticsFiltersMapper({ search, ...body }),
  }).then(resp => (withoutModifications ? resp : AnalyticsFilterHelper(resp)));

export const analyticWidgets = (url, { period, ...body }, widget, query = '') =>
  fetchWrapper({
    url: `analytics/widgets/${url}/${query}`,
    method: 'POST',
    body: {
      ...body,
      candidates: body.candidates.map(s => s.value),
      jobs: body.jobs.map(s => s.value),
      offices: body.offices.map(s => s.value),
      openings_statuses: body.openings_statuses.map(s => s.value),
      recruiters: body.recruiters.map(s => s.value),
      team_leads: body.team_leads.map(s => s.value),
      companies: body.companies.map(s => s.value),
      services: body.services.map(s => s.value),
      from_date: period[0].format('YYYY-MM-DD'),
      to_date: period[1].format('YYYY-MM-DD'),
    },
  }).then(resp => {
    if (widget === 'number') return rejectionNumberHelper(resp);
    if (widget === 'reason') return rejectionReasonHelper(resp);
    if (widget === 'transition') return transitionStepHelper(resp);
    if (widget === 'passing') return passingStepHelper(resp);
    return resp;
  });
